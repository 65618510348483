
import axios from 'axios';

const baseURL = '/api';

const fetchPlantsData = async (endpoint, data = {}) => {
    try {
      const response = await axios.post(baseURL + endpoint, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const d = response.data;
      return d; 
    } catch (error) {
      console.error('Axios error:', error);
      throw error;
    }
  };
  
  export { fetchPlantsData };