import { useLocation, Link } from 'react-router-dom';
import { useState } from 'react';
import { BackgroundImage } from '../components/BackgroundImage';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { LongText } from '../components/LongText'
import { Button } from '../components/Button';


export default function ResultPage() {
  const location = useLocation();
  const plantsDataList = location.state?.plants;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % plantsDataList.length);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const currentPlan = plantsDataList[currentIndex];

  return (
    <Layout>
      <div className="relative py-10 h-full">
        <BackgroundImage className="-bottom-14 -top-36 bg-repeat-y" />
        <Container className="relative bg-slate-50/60 max-w-3xl lg:max-w-full rounded-4xl">
          <h1 className="flex justify-center text-2xl lg:text-4xl font-bold tracking-wide text-green-900 pt-10">
            Plants that suit you
          </h1>
          <div className="mx-auto justify-center  max-w-3xl pt-10 lg:max-h-4xl lg:max-w-full lg:px-12 lg:py-12 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">

            <div>
              <img
                key={currentIndex}
                src={currentPlan.imgUrl}
                alt='plant image'
                className="self-center rounded-3xl h-screen w-screen object-cover"
              />

            </div>
            <div className="font-display text-sm lg:text-xl mx-10 tracking-wide text-green-900">
              <p className="flex justify-center font-bold text-2xl lg:text-4xl uppercase decoration-green-600 hover:underline ">{currentPlan.name_common_en}</p>
              <p className="font-bold my-5">{currentPlan.description}</p>
              <LongText longText={currentPlan.watering_tips}></LongText>
              <p className="font-bold mt-5">{currentPlan.container_tips}</p>
            </div>
            <div />
          </div>
          <div className="flex justify-center">
            <Button onClick={handleNext} className="w-40 mx-3 mb-10 tracking-wide">
              Next
            </Button>
            <Link to="/survey">
              <Button className="w-40 mx-3 mb-10 tracking-wide">
                Retake survey
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

