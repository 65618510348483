import { Link } from 'react-router-dom';
import { BackgroundImage } from '../components/BackgroundImage';
import { Button } from '../components/Button';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { fetchPlantsData } from '../components/PlantsFetcher';



export default function NotFoundPage() {
    return (
        <Layout>
            <div className="relative py-10 h-screen">
                <BackgroundImage className="-bottom-14 -top-36" />
                <Container className="relative background- bg-slate-50/60 max-w-xl lg:max-w-4xl rounded-4xl">
                    <div className="mx-auto max-w-3xl py-10 lg:max-h-4xl lg:max-w-4xl lg:px-12 lg:py-12">
                        <h1 className="font-display text-center lg:text-4xl sm:text-2xl my-auto font-bold tracking-wide text-green-900">
                            Sorry, the page you are looking for does not exist.
                        </h1>
                    </div>
                </Container>
            </div>
        </Layout>

    );
}
