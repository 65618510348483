import { Link } from 'react-router-dom';
import { BackgroundImage } from '../components/BackgroundImage';
import { Button } from '../components/Button';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { fetchPlantsData } from '../components/PlantsFetcher';



export default function WelcomePage() {
  const wakeUpBackend = async () => {
    try {
      await fetchPlantsData('/get-plant-predictions', {});
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <div className="relative py-10 h-screen">
        <BackgroundImage className="-bottom-14 -top-36" />
        <Container className="relative background- bg-slate-50/60 max-w-xl lg:max-w-4xl rounded-4xl">
          <div className="mx-auto max-w-3xl py-10 lg:max-h-4xl lg:max-w-4xl lg:px-12 lg:py-12">
            <h1 className="font-display lg:text-4xl sm:text-2xl my-auto font-bold tracking-wide text-green-900">
              Welcome to <div class="inline-block underline decoration-green-600">plant decore</div>
            </h1>
            <div className="mt-6 space-y-6 font-display lg:text-xl sm:text-sm tracking-wide indent-5 text-green-900">
              <p>
                🌱 Not sure how to decorate with plants or choose the perfect ones for styling?
                Our advanced AI, guided by expert recommendations, is ready to assist you in discovering
                the ideal plant companions.
              </p>
              <p>
                🏡 Transform your home with the allure of nature! Our intelligent system helps you
                find the perfect greenery based on your specific environmental conditions and unique style.
                Whether you're a novice or a seasoned enthusiast, we're dedicated to enriching your space
                with the perfect plants.
              </p>
              <p>
                🤖 How does it work? We ask a few questions about your home conditions — temperature, humidity, lighting —
                and identify plants that will thrive there effortlessly. We believe that with the right guidance, decorating
                with plants can be both easy and rewarding.
              </p>

              <Link to="/survey">
                <Button onClick={wakeUpBackend} className="mt-10 w-full tracking-wide">
                  Take a survey
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </Layout>

  );
}
