import React, { useState, useEffect } from 'react';

export function RadioButton({ options, onOptionsChange }) {
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleRadioChange = (name, value) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(selectedOptions);
    }
  }, [selectedOptions, onOptionsChange]);

  return (
    <div>
      <fieldset className="mt-4">
        <div className="space-y-4">
          {options.map((option, index) => (
            <div key={index} className="flex items-center">
              <input
                id={`radio-${option.param_name}-${option.param_value}`}
                name={option.param_name}
                value={option.param_value}
                checked={selectedOptions[option.param_name] === option.param_value}
                type="radio"
                onChange={() => handleRadioChange(option.param_name, option.param_value)}
                className="hidden peer"
              />
              <label
                htmlFor={`radio-${option.param_name}-${option.param_value}`}
                className="inline-flex items-center text-sm lg:text-xl justify-between w-full p-5 bg-slate-50/60 border border-gray-200 rounded-lg cursor-pointer peer-checked:border-green-600 peer-checked:text-green-600 hover:text-green-600 hover:bg-gray-100 "
              >
                {option.param_title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
