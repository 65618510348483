import React from 'react';

export function LongText ({ longText }) {
  const paragraphs = longText.split('\n').map((paragraph, index) => (
    <p className="py-1" key={index}>{paragraph}</p>
  ));

  return (
    <div>
      {paragraphs}
    </div>
  );
};
