import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import WelcomePage from './pages/WelcomePage';
import SurveyPage from './pages/SurveyPage';
import ResultPage from './pages/ResultPage';
import NotFoundPage from './pages/NotFound';


const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<WelcomePage/>} />
                <Route path="/survey" element={<SurveyPage/>} />
                <Route path="/result" element={<ResultPage/>} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>

        </Router>
    );
};

export default AppRouter;