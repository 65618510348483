import { Container } from './Container';
import { Logo } from './Logo';

export function Header() {
  return (
    <header className="relative z-20 lg:pt-10 self-end w-full">
      <Container className="flex justify-end">
        <div className="mt-10 lg:mt-0 lg:basis-0">
          <Logo className="h-12 w-auto text-slate-900 " />
        </div>
      </Container>
    </header>
  );
}
