import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { BackgroundImage } from '../components/BackgroundImage';
import { Container } from '../components/Container';
import { RadioButton } from '../components/RadioButton';
import { Layout } from '../components/Layout';
import { fetchPlantsData } from '../components/PlantsFetcher';

class Question {
  constructor(text_en, param_name, options_code, options_en) {
    this.text_en = text_en;
    this.options_en = options_en;
    this.options_code = options_code;
    this.param_name = param_name;
  }
}

const questions = [
  new Question(
    'How do you define light in your area?',
    'lightRequirementsHours',
    [0, 2, 4, 6, 8],
    [
      'Barely any light',
      'One-two hours of indirect light',
      'Three-four hours of light',
      'Five-six hours of bright light',
      'Full sun for six hours and more',
    ],
  ),

  new Question(
    'How do you define humidity in your area?',
    'humidityRequirements',
    [10, 40, 70, 80],
    [
      'Rarely over 40%',
      'From 40% to 60%',
      'Often over 70%',
      'Always over 80% ',
    ],
  ),
  new Question(
    'What position do you see your plant in?',
    'placement',
    ['table', 'floor', 'hanging', 'wall'],
    [
      'On a table',
      'On the floor',
      'Hanging down',
      'As a wall piece',
    ],
  ),
  new Question(
    'Do you want a blooming plant?',
    'isFlowering',
    [true, false],
    [
      'Yes',
      'No',
    ],
  ),
  new Question(
    'What is the average temperature in your space?',
    'temperature',
    [15, 18, 25, 40],
    [
      'Cold environment (59 °F)',
      'Mild temperature (65 °F)',
      'Warm space (75 °F)',
      'Hot area (90 °F)'
    ],
  ),
  new Question(
    'Can a pet or a child take a bite of the plant?',
    'isToxic',
    [false, null],
    ['Yes', 'No'],
  ),
];

export default function SurveyPage() {
  const [selectedOptions, setSelectedOptions] = useState({});
  const navigaton = useNavigate();

  const getOptions = (newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
  };

  useEffect(() => { }, [selectedOptions]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const currentQuestion = questions[currentQuestionIndex];

  const handleRedirect = (plantsData) => {
    const plants = plantsData ? plantsData.plants : [
    //   {
    //     "is_fun_for_child": 0,
    //     "is_pest_magnet": 0.3,
    //     "placement": "floor",
    //     "is_flowering": false,
    //     "type_en": "water_loving",
    //     "name_en": "Aglaonema",
    //     "name_common_en": "Aglaonema Red Peacock",
    //     "id": "aglaonema_red_peacock_floor",
    //     "light_requirements_hours_max": 8.0,
    //     "light_requirements_hours_min": 2.5,
    //     "humidity_requirements_max": 70.0,
    //     "humidity_requirements_min": 30.0,
    //     "is_toxic": 1,
    //     "temperature_min": 18.0,
    //     "temperature_max": 30.0,
    //     "is_easy": 0.7,
    //     "description": "Aglaonema Red Peacock, a vibrant member of the Chinese Evergreen family, is prized for its stunning foliage. With its bold red and green hues, this tropical plant adds a touch of sophistication to indoor spaces, making it a popular choice among plant enthusiasts.",
    //     "watering_tips": "They prefer consistently moist soil but not waterlogged conditions. They prefer consistently moist soil but not waterlogged conditions. They prefer consistently moist soil but not waterlogged conditions. They prefer consistently moist soil but not waterlogged conditions. They prefer consistently moist soil but not waterlogged conditions. They prefer consistently moist soil but not waterlogged conditions. They prefer consistently moist soil but not waterlogged conditions.  Allow the top inch of the soil to dry out before watering again.Use room temperature water.\n\nReduce the frequency in the fall and winter when there is less sunny days.\n\n When watering, ensure that water reaches the roots by drenching the soil. Allow excess water to drain away, preventing water from accumulating in the saucer beneath the pot.",
    //     "container_tips": "Perfect container for such type of plant is plastic as they retain moisture more effectively than porous materials like clay and terracotta. Plastic pots help prevent rapid evaporation, maintaining a more consistent soil moisture level. Don't worry about the look, thay can be secondary container inside a decorative one. If you choose clay pot for this plant, be ready to water more.",
    //     "imgUrl": "https://pub-bc398c43357e45f6bbbb942e136f483e.r2.dev/plant-images/aglaonema_red_peacock_floor.jpg"
    // }
    ];
    
    navigaton('/result', {
      state: {
        plants: plants
      }
    }
    );
  };

  const handleNextQuestion = async () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      try {
        console.log(selectedOptions)
        const body = JSON.stringify(selectedOptions);
        const data = await fetchPlantsData('/get-plant-predictions', body);
        handleRedirect(data);
      } catch (error) {
        console.error(error);
        handleRedirect();
      }
    }
  };

  function mapArraysToObject(param_values, param_titles, param_name) {
    return param_values.map((param_value, index) => ({
      param_name,
      param_value,
      param_title: param_titles[index],
    }));
  }

  const renderOptions = () => {
    let params = mapArraysToObject(
      currentQuestion.options_code,
      currentQuestion.options_en,
      currentQuestion.param_name,
    );

    return (
      <div>
        <RadioButton options={params} onOptionsChange={getOptions} />
      </div>
    );
  };

  return (
    <Layout>
      <div className="relative py-10 h-screen">
        <BackgroundImage className="-bottom-14 -top-36" />
        <Container className="relative background- bg-slate-50/60 max-w-2xl lg:max-w-4xl rounded-4xl">
          <div className="mx-auto max-w-3xl py-10 px-10 lg:max-h-4xl lg:max-w-4xl lg:px-12 lg:py-12">
            <h1 className="font-display lg:text-4xl text-2xl my-auto font-bold text-green-600">
              {currentQuestion.text_en}
            </h1>
            <div className="mt-6 space-y-6 text-2xl tracking-wide indent-5 text-green-900">
              {renderOptions()}
            </div>
            <button
              onClick={handleNextQuestion}
              className=" mt-4 bg-green-600 text-white lg:text-xl sm:text-sm py-2 px-4 rounded-md">
              Next Question
            </button>
          </div>
        </Container>
      </div>
    </Layout>

  );
}
