import { Footer } from './Footer';
import { Header } from './Header';

export function Layout({ children, showFooter = true }) {
  return (
    <>
      <Header />
      <main className="flex-auto">{children}</main>
      {showFooter && <Footer />}
    </>
  );
}
