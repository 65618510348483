import clsx from 'clsx';
import backgroundImage from '../images/background.jpg';

export function BackgroundImage({ className, position = 'left' }) {
  return (
    <div
      className={clsx(
        'absolute inset-0 overflow-hidden bg-indigo-50',
        className,
      )}
    >
      <img
        className={clsx(
          'absolute top-0 left-0 w-full h-full object-cover',
          position === 'center' &&
            'transform -translate-x-1/2 -translate-y-1/2 sm:left-1/2 sm:translate-x-[-50%] sm:translate-y-[-50%] lg:translate-x-[-50%] xl:translate-x-[-50%]',
        )}
        src={backgroundImage}
        alt=""
        priority="true"
        unoptimized="true"
      />
      <div className="absolute inset-x-0 top-0 h-40 bg-gradient-to-b from-white" />
      <div className="absolute inset-x-0 bottom-0 h-40 bg-gradient-to-t from-white" />
    </div>
  );
}
