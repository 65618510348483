export function Logo(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="81.000000pt"
      height="81.000000pt"
      viewBox="0 0 1181.000000 1181.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1181.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M5320 10943 c-25 -15 -80 -53 -123 -85 -69 -53 -76 -61 -66 -79 16
-30 35 -33 72 -11 45 29 177 166 177 186 0 22 -7 20 -60 -11z"
        />
        <path
          d="M2491 10918 c-5 -13 -6 -28 -4 -35 25 -66 552 -67 1838 -2 249 12
304 18 281 31 -24 13 -765 27 -1474 28 l-633 0 -8 -22z"
        />
        <path
          d="M5413 10923 c-7 -2 -13 -10 -13 -17 0 -28 106 -161 276 -345 487
-530 590 -662 784 -1011 144 -257 502 -962 493 -970 -4 -4 -55 -48 -113 -97
-58 -50 -114 -100 -124 -112 -32 -36 -36 -23 -10 34 14 30 22 60 18 67 -4 7
-184 261 -399 563 l-392 550 -333 578 -333 577 -81 0 -81 1 -42 59 c-40 56
-46 60 -81 60 -22 0 -313 -27 -648 -60 l-609 -60 -667 0 c-661 0 -666 0 -661
20 3 12 0 20 -7 20 -14 0 -137 -82 -225 -150 -33 -26 -66 -49 -74 -52 -8 -2
-141 -283 -296 -624 l-282 -619 -332 -572 c-182 -315 -331 -578 -331 -584 0
-7 16 -41 36 -76 l36 -65 -32 -74 -32 -73 394 -553 395 -553 316 -548 317
-547 -17 -35 c-18 -38 -24 -40 -118 -50 -33 -3 -63 -9 -66 -13 -4 -4 -131
-280 -282 -614 l-276 -607 -335 -580 c-185 -319 -336 -586 -336 -592 0 -7 16
-41 36 -76 25 -46 33 -68 25 -76 -5 -7 -37 -26 -71 -42 -65 -31 -65 -31 -50
-70 8 -21 16 -26 37 -23 26 3 51 -30 406 -527 l378 -530 -96 -7 c-107 -8 -184
-27 -260 -65 -52 -27 -251 -212 -265 -248 -6 -16 1 -30 37 -66 25 -26 81 -102
126 -170 71 -107 90 -129 137 -157 77 -46 110 -54 236 -59 106 -5 115 -4 165
22 83 41 154 99 190 155 18 28 37 49 43 47 6 -2 28 -34 50 -70 l39 -67 -27
-80 c-31 -96 -33 -150 -5 -207 37 -77 165 -166 277 -193 23 -6 42 -15 42 -21
0 -6 -10 -33 -22 -61 -21 -49 -21 -51 -3 -64 32 -24 45 -16 71 41 l25 55 54 0
54 0 46 -65 c26 -36 51 -65 55 -65 5 0 307 29 671 65 l662 65 678 0 678 0 41
70 c22 38 45 70 51 70 6 0 56 -20 112 -44 189 -82 297 -104 423 -87 104 14
208 65 265 129 62 69 121 201 127 282 5 81 -8 115 -77 193 -28 32 -51 62 -51
67 0 5 -19 33 -42 62 -24 29 -50 66 -58 82 -14 27 -94 100 -150 137 l-24 16
93 207 c74 162 165 329 428 784 183 317 333 583 333 590 0 8 -16 41 -35 73
-19 33 -35 63 -35 66 0 13 53 123 60 123 3 0 24 -31 46 -70 l39 -70 82 0 81 0
47 -65 c25 -36 48 -65 52 -65 9 0 1009 99 1163 115 115 12 288 15 837 15 l692
0 38 69 c41 75 51 81 137 81 23 0 49 6 58 13 9 6 140 285 291 619 l275 608
328 567 c180 313 334 580 341 594 7 14 31 34 54 44 59 27 263 239 349 365 28
41 25 39 -91 -60 -133 -113 -320 -239 -356 -240 -28 0 -25 49 6 116 17 36 28
71 24 77 -4 7 -181 255 -394 553 l-387 541 -338 587 c-327 567 -339 586 -367
586 -26 0 -16 8 64 57 100 59 265 178 288 206 38 47 -29 22 -238 -89 -200
-105 -235 -127 -241 -152 -4 -20 -10 -15 -51 41 -45 63 -46 63 -90 59 -24 -1
-321 -30 -659 -63 l-615 -59 -638 2 -638 3 48 54 c48 54 49 54 82 43 41 -14
211 -15 381 -2 69 6 188 15 265 20 235 17 355 33 1250 165 176 27 554 30 825
7 124 -10 234 -16 245 -12 24 9 -52 39 -195 78 -90 25 -107 26 -330 25 -182 0
-272 -5 -400 -22 -573 -76 -882 -104 -1653 -153 -292 -18 -360 -19 -366 -9 -9
14 -39 3 -74 -27 -9 -8 -20 -13 -23 -10 -3 4 -52 113 -108 245 -299 693 -360
798 -740 1273 -134 168 -611 741 -655 788 -22 22 -34 25 -58 15z m-389 -143
l26 -40 -432 2 c-238 1 -410 3 -383 6 28 2 196 18 375 36 423 44 382 44 414
-4z m-2386 -120 c-12 -16 -31 -30 -42 -30 -12 0 -82 -7 -156 -14 -74 -8 -137
-13 -139 -11 -1 2 7 22 20 44 l23 41 158 0 159 0 -23 -30z m487 25 c-12 -5
-418 -45 -452 -45 -23 0 -23 1 -7 25 l16 25 227 -1 c124 0 222 -2 216 -4z
m2371 -567 l409 -571 356 -616 c196 -338 355 -621 352 -628 -3 -7 -12 -15 -21
-18 -11 -3 -119 -228 -295 -617 l-278 -613 -276 -480 c-152 -264 -277 -481
-278 -483 -5 -7 -945 -75 -951 -69 -1 1 2 36 7 77 12 100 37 356 44 454 3 44
9 84 12 89 3 5 8 48 10 96 2 47 7 89 12 94 4 4 5 24 2 45 l-6 37 -248 3 -248
2 -47 46 c-65 62 -207 158 -299 201 -43 20 -81 41 -84 45 -3 5 -10 8 -15 7
-17 -5 -144 25 -144 33 0 5 -8 8 -17 7 -29 -5 -94 11 -138 33 -56 29 -106 68
-99 79 3 5 -2 9 -10 9 -8 0 -36 27 -61 60 -25 33 -44 60 -41 60 7 0 -55 71
-99 113 -44 42 -65 53 -200 102 -89 32 -102 44 -81 75 6 8 -2 4 -16 -9 l-27
-23 -64 30 c-68 31 -151 98 -141 113 3 5 -2 9 -10 9 -32 0 -106 201 -93 252 3
10 1 21 -4 24 -5 3 -9 12 -9 20 0 8 4 13 9 9 6 -3 9 4 8 15 -1 11 -6 20 -13
20 -6 0 -14 17 -18 38 -12 73 -63 162 -132 228 -36 34 -58 61 -47 58 10 -2 56
-13 102 -24 46 -12 131 -25 190 -30 303 -27 314 -32 491 -205 69 -67 141 -130
160 -140 24 -13 70 -20 162 -25 116 -6 130 -9 161 -33 31 -24 34 -31 40 -94
11 -136 47 -196 238 -399 119 -127 198 -230 278 -363 46 -76 48 -83 54 -176 4
-53 9 -100 12 -106 14 -21 25 5 26 59 0 48 2 54 11 35 6 -13 15 -23 20 -23 5
0 7 -12 6 -27 -2 -18 2 -28 10 -28 8 0 41 69 81 170 91 229 149 354 232 503
90 161 145 241 172 252 52 20 297 308 297 349 0 28 -35 2 -137 -101 -222 -227
-391 -498 -552 -883 -34 -82 -68 -163 -75 -180 l-13 -29 -27 47 c-35 59 -43
93 -50 215 l-6 97 14 -70 c17 -88 45 -187 57 -200 6 -5 13 -5 19 1 6 6 3 31
-10 71 -68 220 -42 385 88 554 88 116 109 158 157 323 25 87 55 176 66 198 35
69 99 123 210 176 126 60 194 108 330 229 57 51 129 111 159 133 84 64 262
147 374 175 122 31 230 43 241 26 4 -7 -24 -44 -72 -94 -138 -144 -198 -255
-257 -477 -74 -277 -152 -370 -381 -454 -124 -45 -167 -73 -199 -129 -25 -42
-29 -62 -55 -274 -6 -51 -21 -105 -37 -140 -40 -85 -145 -184 -279 -262 -228
-132 -321 -200 -295 -216 6 -4 34 11 63 33 29 22 120 80 202 128 237 137 308
203 361 332 18 44 24 76 24 143 0 58 7 108 20 151 36 117 79 159 198 199 89
29 125 48 201 105 74 55 141 142 169 217 11 29 32 111 47 183 42 206 93 294
267 466 59 59 108 111 108 117 0 28 -235 6 -379 -35 -183 -52 -320 -135 -524
-317 -130 -117 -170 -143 -281 -186 -137 -53 -192 -95 -235 -180 -10 -20 -41
-103 -69 -186 -27 -82 -62 -177 -76 -209 -27 -61 -135 -230 -142 -223 -2 2 7
34 21 72 102 270 135 517 99 727 -18 100 -16 245 6 456 14 144 3 229 -46 331
-46 98 -162 214 -292 291 -188 112 -299 189 -385 266 -270 243 -302 258 -266
124 14 -50 22 -120 26 -210 6 -165 -8 -256 -71 -456 -97 -306 -82 -399 113
-686 112 -165 132 -218 139 -360 2 -59 2 -118 -2 -132 l-6 -25 -37 21 c-29 17
-60 24 -142 28 -167 10 -234 49 -360 206 -43 54 -109 101 -175 124 -32 12
-134 27 -255 40 -202 21 -335 41 -401 60 -45 13 -50 13 -50 -4 0 -8 31 -37 70
-66 147 -109 194 -190 215 -369 8 -64 19 -138 24 -166 5 -27 9 -51 8 -52 -1
-1 20 -36 48 -78 46 -71 54 -78 130 -114 44 -21 98 -44 120 -51 22 -7 44 -19
49 -28 6 -10 10 -11 16 -3 17 28 229 -67 281 -127 33 -37 81 -135 99 -201 6
-23 24 -51 41 -65 35 -30 129 -61 216 -71 35 -4 60 -11 57 -16 -3 -5 2 -9 10
-9 9 0 16 4 16 9 0 25 176 -31 300 -95 88 -45 250 -165 250 -185 0 -8 -39 -10
-132 -8 -113 3 -133 2 -133 -11 0 -13 45 -15 345 -15 318 0 348 -2 383 -19 57
-28 71 -63 63 -162 -6 -82 -18 -131 -30 -126 -15 6 -27 1 -21 -9 4 -6 12 -8
19 -6 14 6 -12 -174 -27 -184 -5 -4 -5 -9 0 -12 12 -7 -2 -171 -22 -266 -10
-42 -19 -86 -21 -99 -5 -28 -67 -92 -88 -92 -9 0 -42 -9 -74 -19 -31 -10 -93
-22 -137 -26 -79 -7 -80 -7 -62 12 22 25 12 35 -30 27 -32 -6 -39 0 -34 29 1
5 -4 7 -11 4 -7 -2 -12 -10 -10 -16 1 -6 -5 -14 -13 -17 -8 -4 -15 -12 -15
-19 0 -16 -92 -27 -101 -13 -4 6 -14 8 -22 5 -9 -3 -27 3 -40 13 -13 11 -46
27 -73 37 -35 13 -50 25 -55 43 -4 14 -7 18 -8 8 -1 -20 -38 -13 -63 12 -40
41 -87 181 -74 217 4 9 1 20 -5 24 -8 5 -13 84 -17 268 -6 297 0 333 55 381
33 30 41 33 121 47 24 4 45 13 45 18 4 22 -172 32 -213 12 -14 -7 -33 -29 -44
-50 -17 -36 -18 -56 -8 -395 11 -374 19 -435 63 -511 11 -18 19 -35 17 -36 -5
-5 -664 -25 -816 -25 -139 0 -143 1 -137 20 3 11 3 20 -1 20 -9 0 -82 -60
-156 -128 -32 -30 -60 -53 -62 -51 -1 2 -165 231 -363 509 l-360 505 -321 557
c-177 306 -322 561 -322 565 1 4 40 31 87 58 48 28 105 67 127 87 38 37 39 38
21 58 -25 28 -35 25 -35 -10 0 -17 -5 -30 -12 -30 -7 0 -62 -20 -123 -45 -60
-24 -113 -41 -118 -36 -4 4 126 300 288 657 326 715 244 559 860 1624 l142
245 114 12 c63 6 131 12 152 12 l38 1 -57 -72 c-448 -571 -637 -901 -874
-1528 -120 -318 -306 -908 -292 -922 5 -5 22 -10 38 -11 27 -2 30 2 46 48 9
28 26 108 38 179 12 72 33 168 47 215 100 346 414 1036 631 1387 111 180 435
669 461 697 14 14 93 25 506 66 l490 49 741 1 742 1 408 -572z m-48 222 c111
-192 202 -352 202 -356 0 -4 -112 150 -250 343 -137 193 -250 353 -250 357 0
3 22 6 48 6 l48 0 202 -350z m-3240 202 c-11 -28 -422 -731 -425 -729 -2 2 70
166 160 363 l164 359 37 1 c20 1 39 4 43 8 9 9 25 8 21 -2z m1190 -536 c15 -8
63 -51 107 -97 99 -102 161 -148 341 -255 239 -141 315 -209 374 -335 45 -97
55 -190 40 -376 -6 -86 -10 -204 -8 -262 1 -58 3 -198 2 -311 0 -189 -2 -215
-28 -325 -15 -65 -48 -176 -72 -244 -27 -79 -40 -129 -34 -136 6 -7 4 -30 -5
-60 -8 -26 -15 -65 -15 -86 0 -55 -16 -56 -24 -1 -17 137 -19 190 -5 195 8 2
7 3 -2 2 -13 -2 -18 10 -23 59 -4 33 -25 168 -47 298 -65 388 -133 626 -236
832 -58 116 -132 226 -151 226 -13 0 -13 3 -4 12 18 18 14 38 -8 38 -17 0 -20
-7 -20 -51 0 -66 21 -137 81 -274 57 -132 101 -212 176 -318 l55 -79 39 -192
c22 -105 38 -203 36 -218 -2 -16 0 -28 4 -28 4 0 10 -28 14 -62 3 -35 9 -71
11 -80 4 -12 0 -18 -13 -19 -14 0 -15 -2 -3 -6 20 -8 30 -24 30 -50 0 -17 -6
-23 -21 -23 -11 0 -17 -4 -14 -10 3 -5 2 -10 -3 -10 -5 0 -12 -9 -15 -21 -5
-17 -3 -18 11 -7 13 11 15 11 9 -4 -3 -10 -2 -18 2 -18 5 0 7 -9 4 -20 -3 -11
0 -20 6 -20 11 0 12 6 14 67 1 18 5 29 9 25 12 -12 17 -175 6 -189 -8 -9 -6
-13 6 -13 9 0 16 -3 16 -7 0 -5 7 -66 14 -138 8 -71 13 -131 12 -133 -2 -1
-29 37 -61 85 -32 49 -71 104 -87 125 -17 21 -26 42 -22 52 3 9 1 16 -6 16 -6
0 -8 -6 -5 -12 4 -7 -21 16 -55 52 -201 214 -237 265 -259 368 -15 71 -14 105
2 96 8 -5 7 -2 -2 9 -11 14 -13 44 -7 150 9 166 -8 229 -89 345 -207 295 -216
315 -222 472 -5 110 -2 124 87 450 35 129 41 194 25 310 -22 171 -23 186 -13
203 11 21 12 21 46 3z m3052 -1221 l232 -325 -19 -45 c-10 -25 -20 -45 -23
-45 -3 0 -86 141 -185 312 -239 414 -247 428 -242 428 3 0 109 -146 237 -325z
m-2716 100 c46 -92 127 -300 126 -323 -1 -14 -93 136 -125 203 -18 39 -45 108
-58 155 -14 47 -28 94 -32 105 -17 50 38 -37 89 -140z m-2594 -372 c-89 -197
-170 -373 -180 -392 l-17 -34 -25 46 -25 45 201 350 c110 192 203 348 205 346
2 -1 -70 -164 -159 -361z m8384 -33 l26 -40 -432 2 c-238 1 -417 3 -398 5 153
17 703 71 736 72 38 1 44 -3 68 -39z m-1929 -97 c-178 -23 -795 -77 -795 -69
0 6 14 25 32 43 l32 33 380 -2 c210 0 368 -3 351 -5z m2408 -575 l414 -583
341 -591 c188 -325 344 -599 348 -608 4 -10 -107 -264 -286 -658 -284 -625
-298 -653 -495 -993 -111 -192 -266 -462 -345 -600 -79 -137 -148 -255 -154
-262 -7 -7 -256 -36 -686 -77 l-675 -66 -734 0 c-404 0 -735 1 -736 2 -2 2
-185 258 -409 570 l-405 567 -305 529 c-168 291 -308 536 -312 545 -4 11 -1
17 8 17 22 0 191 89 301 159 55 34 100 61 101 59 2 -2 14 -28 28 -58 45 -98
130 -203 435 -532 327 -353 461 -516 612 -741 l53 -79 5 -311 c3 -249 7 -319
20 -348 14 -34 13 -39 -6 -77 -37 -73 -26 -80 38 -25 l43 36 92 -26 c176 -51
273 -35 481 81 121 68 125 67 125 -10 0 -58 23 -79 117 -108 98 -30 122 -27
198 26 l67 45 23 -25 c32 -35 129 -62 275 -76 145 -14 167 -9 248 60 27 22 51
40 54 40 4 0 9 -13 13 -29 13 -60 71 -91 269 -141 l127 -33 92 46 c51 26 140
84 199 130 512 396 570 447 588 517 10 34 10 84 1 210 -6 91 -16 246 -21 345
-6 99 -15 239 -20 310 -6 72 -14 225 -19 340 -10 278 -19 297 -128 260 -54
-18 -287 -33 -295 -19 -3 5 -1 145 5 311 5 166 7 312 3 326 -8 35 -29 39 -94
21 -63 -19 -210 -15 -269 6 -38 14 -38 13 -18 44 14 22 14 25 -1 40 -10 11
-29 16 -48 14 l-31 -3 -3 100 c-2 55 2 153 7 217 15 181 1 202 -119 174 -66
-15 -112 -12 -158 13 -16 8 -21 18 -17 30 3 10 -1 24 -11 31 -23 20 -86 1
-229 -69 -66 -32 -199 -96 -295 -141 -96 -45 -266 -127 -378 -181 -182 -89
-205 -103 -217 -133 -8 -18 -20 -38 -28 -45 -8 -6 -149 -72 -313 -145 -164
-74 -304 -142 -311 -152 -7 -11 -20 -48 -29 -84 -15 -57 -18 -156 -23 -778
l-6 -714 -57 89 c-71 110 -204 273 -467 572 -307 349 -449 533 -512 664 -18
36 -31 50 -51 54 l-28 5 19 27 c62 90 439 761 683 1216 167 312 555 1063 560
1084 2 8 -8 20 -21 27 -34 17 -58 -1 -119 -89 -182 -263 -738 -1362 -1082
-2137 -40 -91 -78 -170 -84 -177 -23 -24 -379 -153 -425 -153 -40 0 -62 74
-25 82 8 2 46 70 83 153 l69 149 63 12 c35 6 106 17 157 24 51 7 91 15 88 17
-2 2 -65 -2 -140 -8 -75 -6 -139 -9 -142 -6 -3 3 86 207 198 454 l204 448 329
570 c202 349 330 581 331 598 1 40 24 77 48 77 12 1 307 29 656 64 l635 64
757 1 756 1 415 -582z m-55 230 c111 -193 201 -352 200 -353 -3 -3 -491 681
-496 695 -2 6 18 10 45 10 l48 0 203 -352z m-3268 170 c0 -10 -268 -478 -283
-493 -5 -6 -5 -2 1 10 5 11 54 117 107 235 54 118 106 232 117 253 19 38 20
38 39 21 10 -9 19 -21 19 -26z m-5497 -619 c109 -188 196 -343 194 -345 -4 -4
-461 636 -465 651 -2 9 34 27 68 34 3 1 94 -153 203 -340z m7751 -717 c3 -5
-108 -64 -247 -131 -275 -133 -537 -271 -537 -282 0 -8 341 152 630 295 184
91 196 96 235 88 l40 -9 -100 -48 c-341 -161 -541 -257 -693 -332 l-173 -86
-69 6 c-38 3 -79 11 -91 18 l-22 11 22 17 c12 9 56 30 99 46 42 16 172 76 287
133 116 58 284 138 374 178 90 40 173 80 185 88 24 17 52 20 60 8z m2010 -381
l236 -330 -20 -47 c-12 -26 -24 -43 -29 -38 -4 5 -97 164 -206 354 -109 190
-205 355 -213 368 -8 12 -12 22 -9 22 4 0 112 -148 241 -329z m-8788 -416
c240 -336 219 -304 197 -311 -10 -3 -26 -9 -35 -14 -20 -11 -2 -38 -205 315
-159 275 -181 316 -166 300 6 -5 100 -136 209 -290z m7134 276 c0 -5 -15 -18
-32 -29 -40 -24 -350 -151 -543 -222 -148 -54 -338 -140 -367 -166 -21 -20
-14 -39 20 -53 29 -11 245 -41 299 -41 l33 0 1 -147 c4 -624 19 -1436 29
-1573 17 -212 6 -498 -18 -508 -18 -7 -260 22 -314 38 -20 6 -47 19 -60 29
l-23 19 -6 584 c-4 320 -10 633 -13 693 -16 290 -27 921 -17 948 13 34 68 61
466 225 533 219 545 224 545 203z m-1490 -19 c0 -36 -56 -69 -265 -151 -71
-29 -166 -71 -210 -94 -124 -65 -111 -84 84 -123 68 -13 170 -19 216 -12 15 2
19 -2 17 -17 -9 -63 -22 -546 -31 -1150 -6 -379 -15 -751 -21 -826 -5 -74 -10
-165 -10 -201 0 -88 -7 -93 -129 -93 -86 0 -105 4 -180 34 -47 19 -96 45 -109
59 l-25 24 6 1082 c6 1165 6 1161 58 1211 13 12 121 66 239 118 118 53 244
109 280 126 77 36 80 36 80 13z m-2162 -115 c-40 -90 -56 -117 -71 -117 -17 0
-16 6 13 58 58 103 105 180 108 178 1 -2 -21 -55 -50 -119z m3652 68 c0 -8
-180 -91 -305 -140 -379 -150 -475 -194 -475 -215 0 -14 4 -13 42 9 18 11 164
72 323 136 160 64 325 135 367 157 64 34 81 40 100 31 22 -9 19 -12 -37 -41
-100 -50 -261 -121 -420 -184 -158 -63 -245 -105 -245 -120 0 -14 18 -9 70 17
28 14 101 45 163 70 134 52 424 183 487 219 32 18 60 26 95 26 31 0 46 -3 39
-10 -11 -11 -695 -327 -768 -355 -44 -17 -58 -18 -175 -6 -154 15 -183 20
-178 35 6 20 127 73 477 209 190 74 359 141 375 150 29 16 65 22 65 12z m383
-29 c6 -15 -12 -602 -19 -624 -2 -9 -12 -4 -28 14 l-23 28 -49 -19 c-27 -10
-168 -76 -313 -147 -145 -70 -270 -128 -278 -128 -15 0 -17 77 -6 383 l6 196
126 57 c69 32 203 93 296 137 257 120 279 128 288 103z m-1883 -6 c0 -12 -60
-51 -176 -112 -81 -43 -130 -78 -109 -78 19 0 148 58 204 92 72 43 81 46 81
19 0 -14 -22 -31 -78 -61 -42 -23 -88 -50 -102 -61 l-25 -20 25 7 c14 3 58 22
98 42 40 19 76 32 79 28 16 -16 -18 -44 -107 -88 l-95 -47 -90 9 c-49 6 -119
19 -155 29 l-64 18 44 26 c25 14 115 55 201 90 86 36 178 77 205 91 55 28 64
30 64 16z m-2230 -199 c0 -20 -110 -241 -120 -241 -6 0 -15 11 -21 24 -10 21
-4 37 46 125 41 72 62 101 76 101 10 0 19 -4 19 -9z m-171 -87 c-27 -47 -46
-90 -43 -95 3 -6 1 -23 -6 -38 -12 -31 -17 -32 -176 -47 l-71 -6 -36 51 c-33
46 -41 51 -75 51 -20 0 -219 -18 -442 -40 -223 -22 -413 -40 -423 -40 -21 0
-18 21 3 28 8 3 10 0 6 -9 -4 -8 -1 -6 7 4 10 12 29 17 68 17 83 0 192 35 239
75 8 8 78 21 160 30 175 19 601 72 725 90 50 7 95 13 102 14 7 0 -10 -38 -38
-85z m2538 44 c11 -53 25 -58 42 -13 7 21 23 41 38 48 22 10 28 8 44 -12 21
-26 24 -53 9 -82 -17 -31 -28 -22 -23 20 9 67 -30 66 -51 -1 -22 -68 -86 -34
-86 44 0 51 17 48 27 -4z m-118 -250 c7 -150 19 -403 27 -563 8 -159 18 -414
24 -565 5 -151 15 -390 21 -530 12 -297 31 -252 -151 -348 l-120 -63 0 43 c0
24 7 122 15 218 10 117 15 314 15 595 0 412 14 1044 28 1299 l7 130 55 32 c30
18 58 30 61 28 3 -2 11 -126 18 -276z m1117 -110 l-4 -293 -74 -48 c-40 -26
-81 -57 -91 -69 -10 -11 -19 -19 -21 -17 -2 2 -6 145 -9 318 -5 306 -5 314 15
319 11 2 54 22 96 43 42 20 80 38 84 38 4 1 6 -131 4 -291z m-6299 211 c61
-19 442 -23 748 -8 320 16 451 17 480 4 22 -9 33 -10 151 -13 77 -2 81 -3 80
-24 -1 -20 -7 -23 -53 -26 -49 -3 -53 -1 -53 19 0 18 -2 19 -15 9 -8 -7 -13
-16 -10 -20 19 -31 -216 -41 -871 -38 l-622 3 57 70 c45 57 57 67 63 54 4 -10
24 -23 45 -30z m1453 21 c0 -13 -11 -13 -30 0 -12 8 -11 10 8 10 12 0 22 -4
22 -10z m7096 -32 c-8 -13 -96 -165 -196 -338 -100 -173 -186 -319 -191 -324
-5 -6 61 143 146 330 163 359 158 350 228 353 26 1 27 0 13 -21z m-3136 1 c0
-6 -16 -26 -36 -44 l-36 -34 33 -3 c55 -5 48 -28 -9 -28 -70 0 -84 20 -42 59
l33 29 -28 4 c-16 2 -30 9 -33 16 -3 9 13 12 57 12 38 0 61 -4 61 -11z m-3021
-41 l20 -33 -47 -7 c-26 -3 -213 -6 -417 -6 l-370 0 365 38 c464 48 424 47
449 8z m397 -62 c3 -8 -4 -32 -16 -55 -23 -47 -61 -56 -80 -21 -17 32 22 90
61 90 16 0 32 -6 35 -14z m-2976 -78 c0 -4 -17 -8 -37 -8 l-36 0 24 26 c22 23
24 24 36 8 7 -10 13 -21 13 -26z m685 37 c-22 -8 -329 -35 -470 -41 l-151 -7
-17 27 -17 26 332 -1 c183 0 329 -2 323 -4z m843 -7 c9 -9 12 -9 12 0 0 9 45
12 173 11 94 0 168 -2 162 -4 -5 -2 -104 -13 -219 -25 -161 -15 -282 -20 -524
-20 -174 0 -312 3 -309 7 4 3 82 14 175 23 204 21 512 26 530 8z m1130 0 c36
-36 22 -38 -320 -38 -182 0 -328 3 -325 7 4 3 82 14 175 24 204 20 453 24 470
7z m155 -2 c13 -34 -52 -49 -85 -19 -28 26 -22 33 31 33 32 0 51 -5 54 -14z
m2769 -13 c20 -18 24 -73 8 -104 -16 -29 -54 -24 -84 12 -30 35 -32 56 -10 87
18 26 60 29 86 5z m-2567 -3 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4
10 3 0 8 -4 11 -10z m4155 -40 c0 -20 -126 -93 -340 -197 -421 -206 -440 -217
-412 -245 9 -9 60 -15 160 -18 l147 -5 6 -300 c9 -407 43 -789 104 -1155 26
-157 29 -200 14 -200 -27 0 -239 60 -289 81 -63 27 -73 46 -94 169 -76 461
-129 927 -142 1255 -6 155 -6 161 16 188 49 61 115 97 765 412 57 27 65 29 65
15z m464 -84 c3 -28 8 -134 11 -236 3 -102 12 -270 20 -375 8 -104 19 -269 25
-365 5 -96 13 -218 16 -270 10 -140 -2 -162 -153 -283 -262 -212 -471 -375
-528 -414 -71 -47 -125 -76 -132 -70 -2 3 -11 55 -19 116 -8 61 -25 188 -39
282 -43 297 -89 925 -83 1134 l3 110 155 80 c85 44 261 140 390 214 128 74
250 140 269 147 45 16 58 3 65 -70z m-6675 60 c-2 -2 -183 -21 -403 -42 l-398
-39 -24 30 c-13 16 -21 32 -18 35 12 11 155 17 493 18 195 1 352 0 350 -2z
m2134 -529 l378 -529 351 -606 c193 -334 351 -612 351 -618 0 -6 -129 -296
-288 -645 -224 -493 -313 -677 -399 -826 -123 -214 -110 -205 -178 -119 l-38
48 38 72 c21 39 77 141 124 226 47 85 112 207 146 271 46 87 74 126 107 153
54 44 101 135 117 226 8 48 43 129 144 332 130 265 171 358 154 358 -9 0 -112
-158 -229 -353 -45 -75 -86 -135 -91 -132 -5 3 -28 40 -51 83 -23 42 -68 115
-99 162 -32 47 -67 104 -79 128 -12 23 -27 42 -34 42 -18 0 -252 -156 -314
-208 -64 -55 -137 -147 -166 -209 -32 -70 -31 -235 2 -310 24 -54 80 -114 139
-149 30 -17 33 -22 27 -54 -4 -19 -14 -48 -24 -65 -22 -38 -101 -99 -151 -115
-64 -21 -69 -17 -203 215 -75 128 -133 209 -191 264 -30 29 -36 42 -36 77 0
61 -25 181 -49 238 -12 27 -20 50 -19 51 2 1 28 13 58 28 30 14 84 50 120 80
126 104 133 142 47 271 -31 47 -57 88 -57 91 0 3 13 5 28 5 15 0 52 12 81 26
113 57 161 183 161 420 -1 190 16 176 -231 180 -238 4 -259 -1 -361 -76 -74
-55 -122 -113 -139 -170 -11 -36 -14 -38 -63 -45 -28 -3 -70 -15 -93 -25 -47
-21 -129 -92 -138 -121 -6 -19 -7 -19 -36 1 -37 27 -145 76 -187 85 -46 10
-72 54 -72 123 l0 52 103 0 c97 0 107 2 176 35 57 27 97 58 175 134 55 54 112
104 125 111 49 26 166 8 233 -36 21 -13 43 -24 51 -24 32 0 77 136 77 231 0
152 -80 231 -293 293 -94 27 -157 31 -334 21 -142 -9 -173 -3 -323 62 -99 43
-108 45 -180 41 -213 -12 -386 -216 -366 -430 11 -118 70 -230 156 -293 60
-44 111 -62 214 -74 95 -11 111 -23 95 -70 -11 -32 -8 -110 6 -143 6 -15 2
-18 -19 -18 -19 0 -29 7 -36 25 -17 44 -98 122 -153 146 -46 21 -67 24 -172
24 -134 -1 -188 -16 -275 -79 -26 -18 -63 -39 -83 -45 -20 -7 -40 -17 -43 -23
-4 -6 -24 26 -45 71 -67 143 -171 221 -341 255 -42 9 -148 25 -235 37 -175 22
-273 19 -302 -10 -9 -9 -30 -61 -45 -115 -36 -126 -38 -262 -7 -379 23 -89 89
-229 125 -268 28 -31 25 -39 -16 -39 -15 0 -54 -9 -85 -19 -32 -11 -87 -25
-122 -31 -74 -13 -145 -46 -180 -83 -20 -22 -22 -30 -13 -59 6 -18 13 -89 16
-158 5 -144 22 -217 71 -315 69 -137 184 -237 289 -250 52 -7 53 -8 78 -58 14
-29 51 -81 83 -116 32 -36 68 -88 81 -117 33 -75 70 -83 172 -34 35 17 65 30
66 30 2 0 9 -18 16 -40 12 -36 11 -51 -9 -138 -39 -171 -132 -331 -281 -482
-64 -65 -68 -67 -126 -72 -33 -3 -78 -15 -100 -26 -44 -23 -104 -84 -129 -131
-14 -27 -21 -31 -59 -31 -57 0 -97 15 -119 46 -18 23 -18 27 -1 70 41 107 -15
271 -116 338 -43 28 -130 56 -176 56 -17 0 -36 6 -42 13 -5 6 -157 266 -336
577 -302 521 -325 566 -309 578 10 7 55 40 101 72 45 33 82 65 82 71 0 17 -49
5 -142 -36 -45 -19 -82 -34 -84 -32 -1 1 130 290 290 642 286 625 297 648 498
995 113 195 271 468 350 605 l144 250 110 11 109 12 25 -27 c14 -15 34 -40 45
-55 l20 -27 645 64 645 65 645 -2 645 -1 378 -528z m-267 508 c-5 -21 -2 -27
19 -35 14 -5 31 -9 37 -7 11 2 326 -525 327 -547 1 -8 -377 515 -426 590 -15
24 -15 24 17 24 29 0 32 -2 26 -25z m4384 -2 c-1 -12 -86 -61 -265 -153 -187
-97 -325 -178 -325 -190 0 -12 24 -3 90 36 30 17 141 77 245 131 105 55 217
116 249 136 68 42 108 50 62 12 -16 -13 -73 -46 -125 -74 -289 -154 -398 -220
-373 -229 6 -2 31 10 54 26 24 16 106 62 183 102 77 40 173 93 213 118 105 63
177 94 211 90 25 -2 -9 -25 -211 -138 -133 -74 -290 -161 -351 -192 l-110 -58
-153 0 c-85 0 -154 4 -154 8 0 5 127 70 283 146 155 75 318 159 362 186 74 45
115 61 115 43z m-3985 -274 c182 -317 220 -386 212 -378 -4 3 -106 145 -228
315 l-221 309 29 6 c15 4 35 7 43 8 10 0 69 -94 165 -260z m-3457 243 c-11
-28 -422 -731 -425 -729 -2 2 70 166 160 363 154 336 166 359 193 365 43 10
76 10 72 1z m5820 -74 c-5 -15 -88 -24 -88 -10 0 16 13 22 53 22 26 0 37 -4
35 -12z m-2235 -508 c269 -377 373 -533 470 -700 303 -525 388 -674 374 -660
-8 8 -166 228 -352 489 -325 456 -347 490 -573 880 -291 505 -287 498 -275
486 6 -5 166 -228 356 -495z m2167 389 c5 -20 11 -23 37 -21 47 5 49 -16 3
-28 -29 -8 -41 -17 -46 -35 -12 -48 -24 -24 -24 46 0 51 3 70 12 67 7 -3 15
-15 18 -29z m49 -186 c-23 -46 -24 -71 -2 -95 28 -32 5 -33 -40 -2 -23 16 -43
39 -45 50 -4 25 58 84 87 84 18 0 18 -1 0 -37z m-9 -200 c28 -21 50 -40 50
-44 0 -4 -19 -24 -42 -43 -30 -26 -45 -33 -52 -25 -8 7 -3 16 17 31 35 26 34
37 -8 71 -35 27 -45 47 -25 47 6 0 33 -17 60 -37z m-3920 -163 c155 -66 238
-79 378 -60 82 11 115 11 179 0 98 -15 230 -63 282 -101 57 -41 111 -149 57
-114 -48 32 -135 -18 -232 -135 -31 -38 -77 -83 -102 -101 -24 -17 -86 -71
-137 -119 -56 -53 -117 -99 -152 -116 -53 -26 -68 -29 -163 -29 -101 0 -105
-1 -117 -25 -16 -32 -16 -56 1 -114 l14 -47 -26 3 c-42 5 -58 63 -42 147 12
61 12 65 -7 81 -12 9 -59 21 -113 28 -51 7 -115 22 -141 33 -61 26 -132 102
-167 177 -24 50 -27 69 -27 162 0 91 3 112 25 155 34 71 105 142 175 178 121
61 162 61 315 -3z m4695 -315 c13 -137 66 -529 100 -740 7 -39 5 -41 -41 -75
-82 -60 -77 -65 -69 56 3 60 1 213 -5 339 -6 127 -13 340 -15 475 -4 260 0
253 30 -55z m-727 273 c-3 -8 -26 -14 -56 -16 -42 -2 -52 0 -52 12 0 13 12 16
56 16 40 0 55 -4 52 -12z m-66 -160 c3 -29 6 -33 33 -33 39 0 40 -17 1 -30
-24 -8 -32 -17 -34 -38 -2 -16 -8 -27 -15 -24 -8 2 -13 33 -15 80 -2 63 0 77
12 77 9 0 16 -12 18 -32z m84 -215 c7 -26 -2 -72 -18 -90 -16 -19 -36 -16 -63
12 -32 31 -32 69 0 100 21 22 27 24 50 13 14 -6 28 -22 31 -35z m-5426 -163
c280 -40 356 -67 441 -157 69 -73 101 -147 107 -250 3 -58 0 -94 -11 -127 -16
-46 -83 -134 -123 -161 -20 -13 -23 -10 -58 58 -66 126 -142 230 -229 314 -97
93 -246 198 -261 183 -11 -11 -14 -9 47 -44 58 -33 209 -158 256 -212 49 -55
136 -192 172 -268 30 -64 30 -66 12 -89 -23 -32 -137 -86 -200 -96 -73 -11
-168 3 -265 40 -102 39 -143 78 -197 192 -60 124 -76 192 -75 332 0 103 4 132
28 202 14 45 34 87 44 92 27 16 166 12 312 -9z m7657 -22 c-42 -98 -310 -684
-317 -692 -12 -12 -104 -21 -99 -9 8 20 422 733 426 733 2 0 -3 -15 -10 -32z
m-2222 -13 c0 -5 -16 -11 -35 -13 -64 -5 -68 -11 -32 -42 l33 -28 -25 -20
c-14 -11 -26 -22 -26 -24 0 -3 18 -10 40 -17 49 -14 55 -36 8 -26 -82 18 -99
34 -68 65 20 20 20 23 6 39 -21 23 -20 57 2 70 21 13 97 10 97 -4z m-2698 -57
c17 -33 1 -316 -22 -385 -32 -95 -104 -156 -199 -169 -37 -6 -45 -3 -59 16 -8
12 -52 62 -97 111 l-81 89 20 29 c27 37 116 97 203 136 37 17 68 36 68 43 0
18 -3 17 -109 -35 -64 -32 -114 -65 -151 -100 l-55 -54 -52 26 c-28 15 -71 32
-95 39 -34 10 -44 18 -46 37 -6 49 76 141 188 212 39 25 118 33 305 30 166 -2
170 -3 182 -25z m1073 -343 l232 -325 -19 -45 c-10 -25 -20 -45 -23 -45 -3 0
-86 141 -185 312 -239 414 -247 428 -242 428 3 0 109 -146 237 -325z m255 -99
c125 -176 229 -324 231 -330 6 -16 -85 -11 -100 5 -7 8 -93 154 -191 324 -98
171 -185 321 -194 335 l-14 25 20 -20 c11 -11 123 -164 248 -339z m-2905 200
c25 -14 59 -32 77 -40 l32 -16 -29 -63 c-44 -92 -72 -213 -68 -291 4 -97 18
-85 22 19 4 98 38 221 80 285 l23 35 89 -1 c153 0 296 -61 355 -149 28 -41 69
-161 69 -199 0 -12 -16 -55 -35 -95 -28 -58 -55 -92 -124 -161 -122 -119 -196
-173 -335 -241 -160 -80 -168 -79 -245 15 -39 47 -92 96 -150 137 -96 68 -130
112 -178 230 -36 89 -40 158 -14 258 23 93 46 135 110 204 68 73 117 95 216
96 47 1 71 -5 105 -23z m-2675 -405 c-97 -215 -179 -391 -180 -391 -2 0 -14
20 -28 44 l-24 44 201 350 c110 192 203 348 205 346 2 -1 -77 -179 -174 -393z
m3667 349 c72 -27 132 -77 235 -195 110 -126 203 -269 203 -310 0 -42 -115
-151 -207 -197 -64 -31 -73 -33 -173 -32 -162 2 -170 3 -163 19 3 8 0 15 -6
15 -6 0 -11 -4 -11 -9 0 -14 -36 -19 -72 -11 l-33 8 34 1 c19 1 31 4 27 9 -8
7 -13 8 -42 11 -10 1 -14 7 -10 17 3 9 -1 25 -9 37 -13 19 -15 19 -15 4 0 -10
-5 -15 -10 -12 -12 8 6 35 22 35 17 0 40 30 34 45 -3 9 0 15 9 15 8 0 15 4 15
9 0 12 -40 2 -41 -10 -1 -5 -3 -13 -4 -19 -1 -5 -3 -13 -4 -17 -1 -5 -5 -3 -9
2 -11 15 -62 36 -62 25 0 -5 4 -11 10 -15 5 -3 7 -12 3 -20 -3 -8 0 -15 6 -15
19 0 0 -29 -34 -50 -18 -11 -41 -20 -51 -20 -13 1 -10 5 11 14 27 12 28 14 11
21 -11 5 -26 3 -37 -3 -10 -6 -21 -8 -23 -3 -7 10 22 25 41 22 8 -1 10 4 7 14
-4 9 0 15 10 15 8 0 19 -12 22 -26 5 -19 9 -23 18 -14 7 7 7 13 1 18 -6 4 -10
14 -10 22 0 8 -8 21 -18 29 -16 11 -20 11 -32 -5 -7 -11 -17 -16 -21 -11 -4 4
-2 7 4 7 7 0 12 7 12 17 0 15 -2 14 -20 -2 -11 -10 -23 -27 -26 -39 -7 -21 -7
-21 -14 -1 -9 25 -4 34 28 47 37 15 66 17 73 5 4 -6 15 -4 29 6 l22 15 -28 4
-29 3 33 12 c18 7 30 9 28 4 -3 -4 8 -8 24 -9 27 -1 28 1 19 24 -13 35 -12 36
12 24 25 -14 36 -7 17 11 -18 16 -11 66 9 74 13 5 14 3 3 -10 -11 -13 -10 -19
5 -33 17 -16 17 -16 13 4 -3 12 -2 27 2 35 5 8 2 25 -6 39 -13 24 -13 24 -14
4 0 -24 -28 -40 -43 -25 -5 5 -1 12 12 16 23 7 25 13 11 35 -6 10 -10 11 -10
3 0 -7 -6 -13 -13 -13 -8 0 -22 -9 -32 -20 -28 -31 -45 -25 -42 14 2 30 6 34
45 45 29 8 46 18 49 31 3 13 13 20 27 19 11 0 15 -3 8 -6 -7 -2 -17 -13 -23
-24 -10 -20 2 -41 30 -47 16 -3 41 16 41 32 0 6 -7 3 -14 -7 -8 -10 -20 -15
-30 -11 -22 9 -20 31 4 39 11 3 20 12 20 18 0 8 2 9 8 1 10 -15 60 -26 64 -14
5 17 147 -28 235 -75 85 -45 123 -45 55 0 -94 62 -257 113 -374 117 -21 1 -35
5 -32 10 3 4 -4 8 -15 8 -10 0 -21 -4 -23 -9 -1 -5 -18 -12 -35 -16 -29 -6
-33 -4 -33 15 0 11 6 23 13 25 6 3 13 21 14 40 1 26 -2 32 -13 28 -8 -3 -14
-9 -14 -14 0 -5 -4 -9 -10 -9 -5 0 -10 8 -10 19 0 13 10 21 33 25 27 6 29 8
15 17 -12 7 -24 6 -38 -1 -11 -6 -20 -8 -20 -5 0 4 -4 4 -9 1 -5 -3 -5 -22 -1
-42 5 -19 6 -33 3 -30 -4 3 -11 2 -17 -4 -7 -5 -17 -5 -26 0 -13 8 -13 10 -1
10 8 0 11 3 8 7 -4 3 -2 12 3 19 6 6 10 16 9 20 -5 32 2 42 29 39 16 -2 34 0
42 5 8 5 22 4 32 -1 12 -7 24 -6 36 1 15 10 14 10 -5 5 -14 -4 -25 0 -31 12
-8 14 -13 15 -25 5 -20 -17 -52 -5 -36 14 6 8 20 14 29 14 10 0 21 6 23 13 3
7 6 5 6 -5 1 -10 7 -18 15 -18 9 0 12 7 9 19 -5 20 -3 21 87 35 22 3 81 -11
142 -34z m-306 -179 c-16 -6 -17 -9 -6 -16 10 -6 8 -10 -10 -17 -12 -5 -25 -6
-28 -3 -3 3 0 5 6 5 7 0 12 9 12 20 0 14 6 20 23 19 20 0 20 -1 3 -8z m84 -81
c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-60
-16 c0 -14 -3 -14 -15 -4 -8 7 -15 14 -15 16 0 2 7 4 15 4 8 0 15 -7 15 -16z
m-1246 -6 c-3 -4 2 -11 10 -14 9 -3 13 -10 10 -15 -3 -5 -1 -9 5 -9 5 0 13 5
16 10 3 6 12 10 18 9 9 0 9 -2 0 -6 -15 -6 -17 -39 -3 -48 15 -9 12 -25 -5
-25 -9 0 -12 6 -8 16 5 15 3 15 -20 -1 -32 -21 -36 -39 -6 -30 16 5 20 3 16
-6 -2 -8 10 -29 27 -47 17 -18 24 -30 15 -27 -9 3 -21 -1 -28 -8 -9 -12 -8
-16 5 -21 9 -4 19 -4 22 -1 3 3 0 5 -6 5 -7 0 -12 5 -12 11 0 8 5 8 15 -1 8
-7 15 -16 15 -21 0 -12 -97 -11 -105 1 -3 6 -12 10 -18 10 -7 0 -6 -4 3 -10
13 -8 12 -10 -5 -11 -11 -1 -27 -3 -35 -4 -8 0 -35 -10 -60 -22 -25 -11 -55
-23 -66 -27 -24 -7 -63 -46 -47 -46 6 0 39 13 74 30 70 32 124 43 151 29 10
-5 19 -5 22 0 4 5 0 12 -6 15 -7 3 12 4 42 3 37 -1 54 -6 53 -14 -2 -7 4 -10
12 -7 8 4 15 2 15 -4 0 -5 14 -24 32 -41 24 -23 28 -31 15 -31 -9 0 -17 -7
-17 -15 0 -18 12 -19 28 -3 8 8 14 5 21 -15 6 -16 18 -27 29 -27 11 0 31 -11
45 -24 14 -13 28 -21 31 -19 3 3 8 -1 11 -10 15 -38 -50 -167 -84 -167 -9 0
-34 7 -56 15 -22 8 -71 14 -110 14 -79 1 -146 -24 -207 -78 -42 -36 -55 -38
-107 -11 -57 29 -98 66 -141 131 -22 32 -62 83 -90 115 -27 31 -50 64 -50 73
0 9 23 29 51 45 70 39 112 78 174 160 89 120 156 176 175 146 5 -8 9 -4 14 12
l6 23 19 -22 c23 -27 31 -29 31 -7 0 8 4 13 9 10 5 -4 12 0 14 6 4 9 6 8 6 -2
1 -9 11 -24 22 -34 18 -15 19 -20 8 -27 -10 -6 -7 -8 10 -6 35 5 36 20 3 34
-28 13 -39 50 -15 50 7 0 10 -4 7 -9z m66 4 c0 -4 -12 -9 -27 -10 -22 -1 -24
0 -13 8 18 12 40 13 40 2z m1198 -109 c-24 -6 -23 -23 1 -23 23 0 47 -25 31
-35 -5 -3 -10 -3 -10 2 0 4 -18 17 -40 27 -22 11 -40 22 -40 25 0 3 17 7 38 7
20 1 29 0 20 -3z m47 -3 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10
6 0 13 -4 16 -10z m-1236 -45 c0 -5 -6 -2 -13 7 -7 10 -17 14 -22 10 -4 -4 -2
1 6 11 14 18 14 18 21 0 4 -10 8 -22 8 -28z m6429 -5 l-24 -41 -389 1 c-214 0
-390 2 -392 3 -3 4 821 84 826 81 2 -2 -7 -22 -21 -44z m-7338 21 c0 -5 -11
-8 -25 -8 -14 0 -28 3 -31 8 -3 5 9 9 25 9 17 0 31 -4 31 -9z m64 -15 c27 -7
28 -8 11 -26 -16 -18 -16 -19 8 -25 27 -7 27 -35 1 -35 -8 0 -14 -4 -14 -9 0
-5 -8 -7 -18 -4 -21 5 -36 -11 -45 -44 -4 -18 -1 -23 14 -23 10 0 19 7 19 15
0 8 -5 15 -11 15 -6 0 -3 7 6 16 9 9 19 13 23 9 4 -4 1 -10 -6 -12 -10 -4 -10
-8 -1 -20 10 -11 10 -16 0 -22 -10 -6 -9 -12 3 -24 9 -8 16 -25 16 -37 0 -15
5 -20 14 -17 9 4 12 0 9 -13 -5 -18 -6 -18 -24 -1 -12 11 -17 25 -13 34 5 14
-1 17 -30 17 -42 0 -50 -19 -8 -21 16 -1 20 -3 10 -6 -10 -2 -18 -9 -18 -15 0
-6 -11 -26 -25 -44 -14 -18 -25 -39 -25 -47 0 -8 -6 -17 -14 -20 -16 -6 -115
53 -116 69 0 11 -51 26 -59 18 -2 -2 4 -13 14 -24 9 -10 15 -26 12 -35 -4 -8
-2 -15 3 -15 6 0 10 7 10 16 0 8 5 12 10 9 6 -4 7 -11 4 -17 -5 -7 -1 -9 9 -5
9 3 20 1 24 -6 5 -7 31 -23 60 -37 28 -14 49 -28 46 -33 -2 -4 -1 -7 4 -6 23
4 41 -2 39 -13 -3 -23 -17 -38 -28 -32 -7 5 -9 2 -5 -8 3 -9 -6 -44 -20 -79
-28 -68 -35 -161 -20 -250 l8 -46 -38 7 c-124 23 -242 147 -298 313 -25 76
-46 229 -45 322 1 28 -2 60 -5 72 -8 26 28 67 54 60 10 -2 23 3 29 13 14 23
72 32 72 12 0 -8 -6 -12 -15 -8 -8 3 -15 1 -15 -4 0 -6 -7 -10 -16 -10 -8 0
-13 -4 -9 -9 3 -6 17 -4 33 5 15 7 37 14 50 14 13 0 20 4 17 10 -7 11 8 14 18
4 8 -8 -36 -44 -53 -44 -7 0 -3 -13 9 -34 12 -19 18 -41 15 -50 -5 -12 -2 -15
10 -10 9 3 14 10 11 14 -3 5 -2 15 1 24 5 12 10 13 30 4 l24 -11 -22 -19 c-11
-10 -25 -18 -30 -18 -5 0 -3 -7 6 -15 20 -20 26 -19 26 5 0 13 7 20 20 20 14
0 18 5 13 17 -6 14 -2 14 26 -3 19 -11 26 -20 19 -23 -7 -2 -19 -13 -27 -23
-7 -11 -19 -17 -27 -14 -8 3 -14 1 -14 -4 0 -11 15 -12 47 -4 14 4 20 11 16
21 -5 13 -2 13 15 4 29 -16 36 -14 48 13 9 18 9 31 0 48 -9 18 -8 26 6 40 19
19 48 24 48 9 0 -5 -9 -11 -20 -14 -20 -5 -29 -37 -10 -37 6 0 10 7 10 15 0
16 15 21 23 8 2 -5 3 -3 1 2 -4 20 25 47 50 49 14 0 26 7 26 14 0 7 -4 11 -9
8 -5 -4 -12 7 -16 24 -4 20 -13 30 -25 30 -13 0 -17 5 -14 15 4 10 10 12 18 7
6 -5 24 -12 40 -16z m-178 -2 c-9 -8 -21 -12 -28 -8 -8 5 -6 10 8 15 31 12 37
10 20 -7z m64 -15 c0 -5 -9 -9 -20 -9 -11 0 -20 5 -20 10 0 6 5 10 11 10 5 0
7 6 3 13 -5 8 -2 8 9 -2 9 -7 17 -17 17 -22z m-53 -20 c10 0 13 -3 6 -6 -7 -2
-29 1 -49 7 -30 10 -38 10 -46 -1 -7 -10 -11 -11 -14 -3 -3 7 -15 10 -27 8
-12 -2 -4 2 18 10 34 11 44 11 67 0 15 -8 35 -14 45 -15z m111 25 c26 -7 29
-30 5 -36 -27 -7 -43 2 -43 23 0 21 3 22 38 13z m2098 -7 c5 -4 1 -5 -9 -1
-10 3 -17 1 -17 -5 0 -6 9 -11 20 -11 11 0 20 -7 20 -15 0 -15 -23 -11 -47 8
-7 5 -19 7 -26 4 -8 -3 -20 -1 -28 4 -11 7 -11 9 4 9 9 0 17 3 17 8 0 10 56 9
66 -1z m-1945 -22 c19 -3 39 -12 45 -20 7 -9 16 -14 20 -13 5 2 9 -1 9 -7 0
-5 -3 -9 -7 -8 -4 2 -5 -3 -2 -11 3 -7 -3 -19 -12 -27 -16 -13 -17 -11 -12 14
4 19 1 27 -8 27 -8 0 -14 -7 -14 -15 0 -8 -6 -15 -12 -15 -7 0 1 -12 17 -26
21 -17 23 -22 8 -15 -28 14 -33 14 -33 -1 0 -23 -16 -39 -33 -32 -9 3 -17 10
-17 15 0 4 6 6 14 3 12 -5 23 13 30 51 5 25 19 45 32 45 8 0 14 5 14 11 0 5
-4 8 -9 5 -5 -4 -21 -2 -34 3 -19 7 -27 6 -30 -4 -8 -19 4 -27 20 -14 8 7 13
8 13 1 0 -13 -24 -22 -36 -14 -5 4 -10 23 -11 42 -2 21 1 30 6 22 4 -7 23 -15
42 -17z m-171 -28 c9 -4 5 -6 -10 -5 -14 0 -38 -4 -53 -10 -34 -15 -37 -15
-37 -2 0 6 11 10 25 10 19 0 23 4 18 17 -4 11 -3 14 5 9 7 -4 12 -2 12 6 1 9
4 8 13 -3 7 -9 19 -19 27 -22z m-142 0 c2 -9 -2 -17 -8 -17 -7 0 -10 7 -6 15
6 17 -9 21 -19 5 -8 -13 -25 -13 -25 -1 0 10 28 20 45 16 6 -1 11 -9 13 -18z
m6665 16 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-6478 -21
c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m1980 8 c-3 -5 -11
-10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m-2146 -36 c-6
-8 -18 -14 -25 -14 -11 0 -10 5 4 18 19 21 39 18 21 -4z m3681 -25 c11 -21 44
-76 74 -121 29 -46 78 -128 107 -183 54 -100 54 -100 54 -190 0 -78 -4 -98
-29 -153 -16 -34 -46 -78 -67 -97 -38 -34 -49 -39 -76 -36 -7 1 -13 -7 -13
-17 0 -10 -11 -24 -25 -30 -35 -16 -45 -15 -45 4 0 13 -6 10 -25 -10 -18 -19
-31 -25 -45 -21 -22 7 -26 20 -10 30 6 3 10 1 10 -4 0 -6 4 -11 9 -11 5 0 7 9
4 21 -3 12 -11 18 -19 15 -8 -3 -14 1 -14 10 0 9 9 18 20 21 21 6 18 23 -5 23
-7 0 -19 -17 -26 -37 -9 -26 -14 -32 -17 -20 -2 10 2 25 9 33 10 12 8 19 -10
38 -16 17 -21 31 -16 45 3 12 11 18 17 15 6 -4 5 -10 -3 -15 -10 -6 -11 -9 -1
-9 6 0 12 -7 12 -15 0 -19 10 -19 35 1 19 15 19 15 1 12 -14 -2 -21 5 -27 25
-6 21 -4 27 9 27 14 0 14 2 3 9 -9 6 -11 15 -6 27 5 11 10 26 11 34 1 8 12 59
25 112 12 54 19 101 16 105 -12 11 -19 -4 -33 -74 -26 -122 -33 -143 -49 -137
-8 4 -15 2 -15 -4 0 -6 6 -12 12 -14 20 -7 -53 -141 -77 -143 -17 -1 -20 5
-20 44 0 25 4 52 9 59 4 7 6 15 5 16 -44 33 -88 48 -74 26 4 -6 11 -8 16 -5 5
4 9 -1 9 -10 0 -12 -5 -15 -16 -11 -9 3 -19 6 -23 6 -4 0 -13 27 -20 61 -9 47
-16 59 -27 55 -9 -3 -12 -1 -9 4 3 6 2 10 -3 10 -5 0 -14 -10 -20 -22 -10 -23
-11 -23 -11 5 -1 18 4 27 14 27 8 0 15 5 15 11 0 6 -7 9 -15 5 -8 -3 -15 -1
-15 4 0 6 -4 10 -10 10 -5 0 -10 -7 -10 -15 0 -8 -7 -15 -15 -15 -17 0 -20 16
-5 25 6 4 8 10 5 15 -3 5 6 7 21 4 24 -4 25 -4 10 12 -21 21 -14 29 13 15 12
-7 18 -17 15 -26 -4 -9 0 -15 10 -15 9 0 16 5 16 10 0 6 5 10 11 10 6 0 4 -9
-4 -21 -13 -18 -12 -23 10 -45 27 -27 38 -31 30 -10 -3 8 -1 17 6 20 7 2 2 5
-10 5 -28 1 -29 15 -3 66 22 44 24 55 10 55 -5 0 -10 -6 -10 -12 0 -21 -13
-35 -39 -43 -22 -7 -24 -5 -18 24 4 18 2 31 -3 31 -6 0 -8 11 -4 26 4 17 2 29
-7 35 -12 8 -12 11 1 19 17 11 10 13 -14 4 -9 -3 -13 -10 -11 -15 13 -20 11
-78 -2 -84 -8 -2 -11 -1 -8 5 7 11 -29 32 -39 23 -3 -3 -6 -1 -6 6 0 9 8 11
24 7 30 -7 41 3 19 19 -15 11 -15 15 5 42 31 43 150 153 167 153 7 0 15 5 17
11 5 14 201 146 211 141 4 -2 16 -21 27 -43z m-3738 29 c-18 -18 -15 -28 8
-28 11 0 20 -4 20 -9 0 -11 -49 -1 -57 12 -3 5 2 16 12 24 23 16 33 17 17 1z
m475 -12 c-16 -18 -31 -8 -16 10 6 7 15 11 20 8 5 -3 3 -11 -4 -18z m-347 8
c0 -3 -13 -10 -29 -15 -31 -11 -33 -38 -3 -35 9 0 18 -8 20 -19 2 -11 0 -16
-5 -10 -4 6 -20 16 -35 24 -14 7 -24 19 -22 25 6 17 74 44 74 30z m5525 0
c-11 -2 -195 -20 -409 -41 l-389 -37 -29 34 c-15 19 -28 38 -28 42 0 4 197 7
438 7 240 -1 428 -3 417 -5z m-5708 -19 c0 -8 -4 -15 -8 -15 -11 0 -20 16 -12
24 11 11 20 7 20 -9z m376 1 c4 -9 -3 -16 -17 -20 -20 -5 -23 -11 -19 -41 2
-22 -1 -35 -8 -35 -6 0 -9 7 -5 15 3 8 0 15 -7 15 -8 0 -7 4 3 10 13 9 13 11
0 20 -10 6 -11 10 -2 10 6 0 12 6 12 14 0 13 13 23 31 25 4 1 9 -5 12 -13z
m109 -18 c-7 -7 -12 -8 -12 -2 0 6 3 14 7 17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z
m38 -8 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z
m3974 -50 c12 -23 0 -47 -188 -372 -110 -191 -202 -346 -203 -344 -2 1 69 162
158 357 89 195 168 370 176 389 15 33 16 34 30 15 8 -11 20 -31 27 -45z
m-4056 12 c6 -18 -15 -14 -22 4 -4 10 -1 14 6 12 6 -2 14 -10 16 -16z m1742
-23 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z
m-1720 -7 c0 -5 -7 -9 -15 -9 -8 0 -15 4 -15 9 0 4 7 8 15 8 8 0 15 -4 15 -8z
m50 4 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-130 -21 c-7 -8 -18 -15 -24 -15 -6 0 -2 7 8 15 25 19 32 19 16 0z m1986 -16
c0 -23 -3 -23 -22 -5 -18 19 -18 21 4 23 11 1 18 -5 18 -18z m-58 -24 c-3 -3
-13 -2 -22 1 -14 5 -15 9 -4 15 13 8 36 -6 26 -16z m-1918 -6 c0 -5 -4 -9 -10
-9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m140 0 c0 -13 -39
-17 -49 -5 -7 8 -1 11 20 11 16 0 29 -3 29 -6z m1544 5 c8 -3 12 -12 9 -20 -3
-8 0 -14 7 -14 6 0 18 -7 26 -15 18 -18 11 -19 -19 -4 -51 27 -71 71 -23 53z
m-1720 -19 c-4 -8 -10 -12 -15 -9 -5 3 -9 -2 -9 -10 0 -9 -4 -16 -10 -16 -15
0 -12 16 6 34 20 20 35 21 28 1z m96 -6 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10
16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-152 -18 c2 -6 11 -18 19 -27 13 -12 18
-13 24 -3 4 8 10 11 14 8 3 -3 -5 -14 -17 -25 -12 -10 -18 -13 -14 -6 5 7 1
12 -9 12 -11 0 -15 -5 -12 -14 3 -8 -1 -20 -9 -27 -11 -9 -18 -8 -34 6 -25 23
-25 30 0 32 11 1 20 -3 20 -9 0 -6 2 -9 5 -6 3 3 -1 20 -8 37 -13 31 -15 32
-31 15 -11 -10 -16 -27 -13 -41 6 -30 -5 -36 -29 -18 -11 8 -14 14 -6 15 6 0
12 9 12 21 0 11 4 18 9 15 5 -3 11 3 14 13 3 15 12 19 33 16 16 -1 30 -8 32
-14z m102 10 c0 -6 -4 -13 -10 -16 -5 -3 -10 -1 -10 6 0 8 -4 8 -15 -1 -13
-11 -14 -10 -9 4 6 17 44 23 44 7z m177 -43 c-8 -5 -7 -8 6 -8 20 0 21 -4 6
-31 -11 -22 -31 -17 -22 6 3 8 0 15 -6 15 -6 0 -11 -5 -11 -10 0 -6 -11 -8
-26 -6 -16 3 -27 0 -31 -10 -4 -10 -9 -12 -18 -4 -7 6 -21 7 -34 2 l-22 -8 21
-22 c26 -28 34 -28 20 -1 -15 28 -5 37 12 10 7 -12 19 -21 27 -21 11 0 11 3 1
15 -7 8 -11 15 -9 16 55 10 78 3 79 -23 0 -17 -20 -7 -21 10 -1 16 -2 16 -6
-1 -2 -9 -9 -15 -14 -12 -5 3 -6 -2 -2 -11 4 -11 2 -15 -4 -11 -7 4 -10 -3 -9
-18 1 -30 12 -33 21 -5 3 11 11 20 16 20 10 0 49 -36 49 -46 0 -4 -7 -1 -15 6
-13 11 -15 8 -14 -16 0 -16 3 -23 6 -16 2 6 11 12 19 12 11 0 12 -3 4 -12 -6
-7 -18 -22 -26 -33 -9 -11 -13 -14 -9 -7 3 6 1 12 -5 12 -6 0 -10 -8 -7 -17 4
-22 -19 -73 -34 -73 -6 0 -9 -7 -5 -15 3 -8 -3 -40 -14 -71 -17 -54 -17 -84 0
-84 15 0 24 23 22 56 -2 18 2 35 8 39 5 3 10 17 10 31 0 13 5 24 10 24 6 0 10
-7 10 -15 0 -8 6 -15 14 -15 20 0 8 40 -13 41 -14 1 -14 2 1 6 9 2 18 16 20
31 l3 27 14 -24 c10 -18 10 -25 1 -31 -9 -5 -9 -9 -1 -14 6 -4 11 -18 11 -32
0 -24 14 -32 24 -15 4 5 0 12 -6 15 -9 3 -8 8 5 16 9 6 17 16 17 21 0 6 -4 8
-8 5 -4 -2 -9 10 -10 27 l-2 32 15 -34 c9 -19 13 -42 10 -51 -3 -11 1 -20 11
-24 12 -5 15 -14 11 -39 -5 -28 -5 -30 5 -9 6 12 15 22 20 22 5 0 1 -14 -7
-30 -14 -26 -18 -29 -36 -20 -11 6 -25 9 -32 6 -7 -3 -6 -5 3 -5 32 -2 40 -22
23 -55 -9 -17 -18 -38 -19 -46 -1 -8 -6 -35 -13 -60 -6 -25 -11 -82 -11 -127
l0 -82 -55 -26 c-30 -15 -69 -28 -87 -28 -29 -2 -34 3 -62 56 -17 32 -55 86
-85 120 -30 34 -70 96 -90 137 -34 71 -36 80 -35 175 0 79 5 112 23 157 23 59
111 168 136 168 8 0 21 7 29 16 14 13 16 13 16 0 0 -10 7 -13 27 -10 15 3 30
7 34 10 4 2 9 -1 11 -8 6 -16 28 0 28 20 0 14 -2 14 -9 3 -7 -11 -11 -9 -21 9
-7 13 -8 20 -2 16 6 -3 13 -2 17 4 10 16 35 12 29 -5 -3 -8 1 -21 9 -29 12
-13 17 -13 26 -2 6 7 8 16 4 19 -3 4 -1 7 5 7 6 0 9 8 6 18 -5 14 -3 14 10 -2
12 -16 13 -22 3 -28z m1513 3 c44 -23 136 -56 225 -81 208 -57 247 -71 240
-88 -3 -7 -5 -20 -5 -27 0 -11 -7 -10 -32 5 -18 10 -105 40 -193 65 -187 55
-238 74 -299 113 -36 22 -43 31 -34 40 16 16 12 17 98 -27z m-1640 -9 c0 -5
-7 -9 -15 -9 -8 0 -15 4 -15 9 0 4 7 8 15 8 8 0 15 -4 15 -8z m176 -38 c-5
-14 14 -39 36 -47 10 -3 8 -10 -6 -23 -16 -17 -18 -17 -13 -2 4 12 2 15 -9 11
-10 -3 -12 -13 -8 -27 5 -19 4 -19 -9 -6 -18 18 -33 75 -17 65 9 -6 15 14 11
44 -2 7 3 10 9 6 6 -4 9 -13 6 -21z m1404 -8 c0 -8 6 -16 13 -19 6 -2 9 -8 5
-12 -4 -4 -14 -2 -23 5 -17 14 -20 40 -5 40 6 0 10 -6 10 -14z m-134 -8 c4 -6
2 -14 -4 -16 -7 -2 -12 -8 -12 -13 0 -4 7 -6 14 -3 8 3 21 1 28 -6 7 -6 41
-31 75 -54 34 -24 59 -47 55 -51 -4 -4 3 -4 15 -1 16 5 26 1 37 -16 9 -12 16
-19 16 -14 0 5 9 1 20 -9 21 -19 20 -19 -26 -66 -30 -30 -52 -45 -62 -41 -10
3 -13 1 -8 -6 4 -7 -9 -38 -29 -70 -39 -64 -125 -292 -125 -334 0 -24 8 -32
59 -57 32 -17 61 -36 64 -44 3 -8 36 -35 74 -61 118 -82 130 -91 125 -106 -3
-12 -2 -13 6 -1 8 11 17 11 43 2 19 -6 72 -12 119 -13 78 -3 85 -5 82 -23 -1
-11 -5 -39 -8 -62 -3 -24 -1 -51 5 -60 9 -16 10 -16 11 5 0 12 5 22 10 22 6 0
10 -4 10 -10 0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -24 0 -17 17
8 22 26 5 36 -4 55 -45 16 -36 15 -42 -3 -27 -12 10 -15 9 -15 -5 0 -14 -3
-15 -15 -5 -12 10 -15 9 -15 -8 0 -11 8 -22 20 -25 11 -3 20 -10 20 -17 0 -6
-5 -8 -11 -4 -6 3 -21 -4 -33 -17 -22 -24 -22 -24 -3 -34 25 -12 37 -41 14
-32 -9 3 -19 2 -22 -4 -4 -5 1 -12 9 -15 22 -9 20 -24 -3 -24 -16 0 -19 6 -16
35 2 19 -1 35 -6 35 -5 0 -7 11 -3 25 4 15 2 25 -4 25 -17 0 -24 -37 -14 -74
8 -27 7 -36 -4 -40 -7 -3 -16 0 -20 5 -4 7 -10 7 -17 0 -6 -6 -20 -11 -31 -10
-12 0 -15 3 -8 6 22 9 13 20 -13 15 -18 -3 -25 -11 -25 -28 0 -13 5 -24 10
-24 6 0 10 4 10 10 0 5 7 7 15 3 14 -5 14 -7 0 -23 -8 -9 -15 -24 -15 -33 0
-12 -9 -17 -30 -17 -42 0 -38 -16 5 -18 24 -1 35 3 35 12 0 9 7 12 19 9 11 -3
21 2 25 12 5 13 2 15 -16 9 -20 -6 -21 -5 -9 10 17 20 25 20 45 1 8 -9 20 -14
27 -13 6 2 14 -3 16 -9 3 -8 -4 -13 -19 -13 -14 0 -28 -3 -32 -7 -4 -5 -12 -9
-17 -9 -22 -3 -23 -5 -9 -14 13 -8 12 -10 -2 -10 -10 0 -18 -4 -18 -10 0 -11
30 -1 48 17 7 7 21 12 30 12 14 -1 14 -2 -1 -6 -10 -3 -16 -11 -13 -18 3 -8 0
-15 -5 -17 -6 -2 -7 -12 -1 -28 9 -23 9 -23 19 -5 6 11 9 28 8 38 -5 27 12 10
17 -18 4 -17 1 -25 -8 -25 -10 0 -12 -9 -7 -30 3 -18 1 -30 -5 -30 -6 0 -12 8
-14 18 -2 10 -6 13 -10 6 -10 -16 16 -59 34 -56 11 1 16 -6 16 -23 0 -21 -3
-23 -15 -13 -11 9 -16 9 -24 -3 -5 -8 -8 -18 -8 -24 0 -5 3 -4 6 3 7 17 23 15
23 -3 0 -8 5 -15 10 -15 6 0 10 -4 10 -10 0 -5 -8 -7 -17 -3 -15 5 -14 2 4
-18 28 -31 41 -98 22 -110 -11 -7 -11 -9 2 -9 13 0 17 -11 17 -47 1 -42 -3
-51 -31 -75 -17 -15 -45 -33 -62 -39 -16 -6 -43 -17 -60 -24 -128 -55 -323
-85 -555 -85 -141 0 -160 2 -169 18 -6 9 -11 11 -11 5 0 -19 -46 -16 -66 4
-13 13 -19 14 -22 5 -6 -20 -19 -14 -26 13 -4 14 -11 25 -17 25 -6 0 -8 -9 -4
-20 4 -11 2 -20 -4 -20 -6 0 -11 4 -11 10 0 5 -6 7 -14 4 -8 -3 -23 0 -33 6
-14 9 -15 10 -1 6 9 -2 23 3 32 13 20 22 20 31 1 31 -8 0 -15 -4 -15 -9 0 -16
-76 -35 -85 -21 -2 3 0 12 5 20 7 11 11 11 24 1 15 -12 20 -8 17 11 0 5 8 8
19 8 10 0 30 11 44 25 31 31 46 32 46 3 1 -21 2 -22 14 -5 7 9 25 17 39 17 14
0 29 5 33 11 4 8 -6 9 -35 5 -51 -7 -54 8 -5 24 29 10 38 10 44 0 5 -9 9 -9
14 -1 4 6 1 11 -6 12 -7 0 -2 5 11 10 13 5 21 12 18 15 -3 4 -14 3 -24 -1 -21
-9 -21 -9 -50 0 -18 5 -22 3 -17 -8 4 -10 -5 -19 -30 -27 -19 -6 -36 -16 -38
-21 -5 -15 -58 1 -58 17 0 8 7 14 15 14 8 0 15 -4 15 -10 0 -14 11 -12 34 5
10 7 15 19 11 25 -5 8 -11 5 -20 -7 -13 -17 -14 -17 -34 6 -12 13 -21 28 -21
33 0 4 -11 8 -24 8 -14 0 -28 5 -31 10 -4 6 -9 8 -13 6 -4 -2 -8 10 -9 27 -2
22 0 27 6 18 5 -8 16 -11 25 -8 13 5 15 11 6 26 -6 12 -16 18 -25 15 -9 -4
-16 0 -16 7 -3 33 -7 55 -13 74 -5 15 -4 15 5 -2 7 -13 16 -23 21 -23 5 0 6
-4 3 -10 -3 -5 -1 -10 5 -10 6 0 8 -4 5 -10 -3 -5 1 -10 9 -10 19 0 33 23 18
28 -7 2 -12 13 -12 24 0 14 -5 18 -15 14 -22 -8 -19 27 4 48 15 13 20 14 24 4
4 -10 8 -10 16 2 8 12 10 8 8 -17 -1 -19 -8 -33 -14 -33 -7 0 -13 -4 -13 -10
0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 4 -10 9 -10 5 0 7 9 4 20 -3 12 -2
20 3 19 15 -2 54 13 54 21 0 17 -13 40 -22 40 -5 0 -7 -4 -4 -9 10 -16 -5 -41
-25 -41 -17 0 -18 3 -9 21 14 26 7 33 -31 35 -21 1 -39 -7 -59 -27 -32 -33
-45 -36 -54 -13 -3 8 -2 12 4 9 11 -7 60 23 60 37 0 6 -9 8 -20 5 -31 -8 -38
35 -10 65 20 21 21 22 18 3 -1 -11 -7 -23 -13 -27 -5 -4 -6 -10 -2 -14 8 -9
38 16 32 26 -3 4 2 11 10 14 13 5 14 2 4 -15 -8 -15 -8 -24 0 -32 8 -8 11 -6
11 7 0 12 5 16 15 13 8 -4 17 -2 20 3 4 6 -1 10 -9 10 -19 0 -20 5 -5 31 8 15
7 24 -3 36 -12 15 -11 15 10 4 12 -6 27 -9 32 -6 5 3 11 1 15 -5 3 -5 11 -10
17 -10 7 0 6 6 -2 15 -10 12 -10 15 3 15 8 0 17 7 21 15 3 8 15 15 26 15 11 0
20 5 20 11 0 7 -11 8 -30 4 -19 -4 -30 -3 -30 4 0 6 -4 11 -8 11 -5 0 -9 -7
-9 -15 0 -8 5 -15 11 -15 11 0 -25 -29 -36 -30 -5 0 -8 7 -8 16 0 10 -6 14
-14 11 -12 -5 -13 -1 -4 24 5 16 16 29 24 29 8 0 14 7 14 15 0 8 -4 15 -10 15
-5 0 -10 -4 -10 -10 0 -5 -4 -10 -10 -10 -13 0 -13 37 -1 44 5 4 21 2 35 -4
17 -6 26 -6 26 0 0 6 -4 10 -10 10 -16 0 -12 30 5 36 19 8 20 24 1 24 -16 0
-22 13 -42 88 -17 65 -17 62 0 62 31 0 33 26 25 386 -7 345 -8 363 -32 430
-13 38 -27 76 -31 84 -4 10 5 27 24 48 16 18 30 39 30 46 0 7 6 21 14 32 8 10
12 29 9 42 -7 31 7 27 46 -13 19 -20 44 -38 56 -41 27 -7 140 30 173 57 14 10
33 19 43 19 11 0 19 4 19 8 0 8 36 30 52 31 4 1 10 -4 14 -11z m215 -32 c17
-19 28 -24 49 -19 25 5 27 3 23 -20 -4 -22 -2 -24 23 -19 26 5 26 6 8 20 -18
13 -16 14 15 8 41 -7 50 -14 32 -25 -9 -6 -6 -10 11 -15 20 -6 21 -8 6 -16 -9
-6 -27 -9 -40 -8 -14 2 -22 -2 -20 -9 4 -21 -11 -15 -48 20 -19 17 -52 41 -73
52 -34 17 -37 20 -22 31 15 11 15 13 -2 17 -16 4 -17 5 0 6 9 0 27 -10 38 -23z
m689 -2 c0 -8 -6 -14 -13 -14 -8 0 -25 -9 -38 -21 l-24 -21 30 8 c17 4 36 11
43 17 8 7 12 5 12 -6 0 -8 -7 -17 -15 -21 -23 -8 -18 -28 5 -21 11 4 20 2 20
-4 0 -6 -6 -11 -12 -11 -9 0 -10 -3 -2 -8 6 -4 13 -19 16 -34 7 -36 -20 -47
-44 -19 -9 12 -21 21 -26 21 -5 0 -13 7 -16 16 -4 9 -9 14 -13 11 -9 -10 -43
12 -43 28 0 20 19 19 26 0 9 -22 23 -18 17 5 -3 11 -9 18 -13 15 -5 -3 -10 1
-13 7 -3 9 -11 5 -26 -11 -11 -13 -21 -19 -21 -13 0 7 -7 12 -16 12 -32 0 -2
17 53 30 36 9 65 22 77 36 20 24 36 23 36 -2z m-3812 -356 c111 -193 201 -352
199 -354 -5 -6 -462 639 -465 657 -3 17 32 49 53 49 5 0 101 -158 213 -352z
m1701 263 c-8 -2 -7 -6 2 -11 7 -5 11 -24 10 -47 -2 -21 -3 -46 -4 -55 -1 -10
-5 -18 -10 -18 -4 0 -5 -4 -2 -10 3 -5 1 -10 -5 -10 -7 0 -9 7 -6 16 4 10 -1
25 -11 36 -16 18 -16 19 5 25 22 5 31 23 12 23 -5 0 -10 5 -10 11 0 5 5 7 10
4 14 -9 12 2 -4 28 -10 16 -11 28 -4 47 l10 25 9 -30 c6 -20 5 -31 -2 -34z
m539 44 c60 -18 108 -57 152 -123 58 -88 62 -115 71 -487 4 -187 9 -353 11
-370 l3 -30 -140 3 c-297 7 -437 47 -556 160 -121 113 -157 224 -125 382 28
137 95 247 168 279 23 10 53 36 76 66 40 54 115 109 166 124 48 14 117 12 174
-4z m2106 0 c-4 -8 -1 -15 5 -15 6 0 8 -7 5 -15 -9 -22 -21 -18 -27 7 -4 13
-1 26 6 30 17 11 17 10 11 -7z m-1003 -5 c6 0 7 -4 4 -10 -10 -15 -32 -12 -39
6 -5 12 -2 15 10 10 8 -3 20 -6 25 -6z m429 -121 c131 -93 200 -179 339 -419
80 -138 100 -160 143 -160 84 0 207 95 231 178 16 52 37 68 37 27 0 -84 -112
-198 -223 -226 -26 -6 -47 -15 -45 -18 2 -4 37 -36 78 -72 41 -36 85 -80 97
-98 26 -39 58 -122 69 -177 7 -38 7 -38 -16 -23 -15 10 -28 34 -37 70 -20 79
-60 137 -133 194 -105 81 -151 135 -219 256 -126 225 -144 251 -209 321 -68
72 -167 149 -215 167 -22 9 -28 7 -37 -9 -12 -22 -39 -27 -41 -7 -1 6 -3 33
-4 60 l-2 47 65 -36 c37 -20 91 -54 122 -75z m-2105 52 c-3 -3 -11 1 -16 9 -9
12 -10 11 -6 -6 5 -25 -18 -61 -28 -44 -4 6 -2 17 4 24 6 7 8 16 4 19 -3 4 2
15 13 26 18 17 19 17 27 -2 4 -11 5 -22 2 -26z m106 22 c10 -11 10 -14 2 -9
-8 4 -13 2 -13 -8 0 -8 5 -18 12 -22 7 -5 3 -10 -12 -16 -12 -5 -25 -6 -28 -3
-3 3 0 5 6 5 7 0 12 7 12 15 0 8 -5 15 -10 15 -6 0 -5 8 2 20 15 24 13 24 29
3z m1718 -3 c37 -18 81 -63 81 -81 0 -20 -131 -139 -216 -196 -94 -64 -166
-134 -145 -141 6 -2 21 8 32 22 11 14 67 58 125 97 57 39 130 99 162 134 l58
63 34 -15 c75 -31 113 -90 113 -173 0 -146 -182 -331 -370 -377 -54 -14 -80
-15 -147 -6 -86 11 -241 63 -278 93 l-22 17 39 109 c69 197 103 251 222 360
121 110 222 141 312 94z m-1676 -36 c-3 -8 2 -23 11 -33 12 -13 14 -21 7 -26
-6 -3 -11 -1 -11 6 0 6 -15 -4 -34 -24 -18 -20 -36 -33 -40 -30 -3 4 -6 -1 -6
-10 0 -9 -5 -17 -11 -17 -6 0 -9 7 -5 15 3 8 1 15 -5 15 -6 0 -7 5 -4 10 4 6
11 7 17 4 15 -10 29 18 16 33 -10 13 1 18 26 14 15 -3 26 22 19 47 -4 17 -2
21 10 16 9 -3 13 -12 10 -20z m2499 -9 c3 -14 1 -25 -3 -25 -5 0 -9 11 -9 25
0 14 2 25 4 25 2 0 6 -11 8 -25z m-2450 -17 c-7 -7 -12 -8 -12 -2 0 6 3 14 7
17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z m1819 -33 c15 10 100 -54 179 -135 63
-64 90 -104 170 -249 112 -203 137 -236 247 -319 70 -54 87 -73 114 -127 31
-64 35 -104 8 -95 -8 2 -48 5 -90 4 -101 0 -167 -32 -244 -116 -87 -96 -121
-189 -132 -353 -7 -108 0 -117 88 -125 l57 -5 -124 -220 c-68 -121 -127 -226
-131 -233 -6 -10 -161 -28 -563 -68 l-555 -54 -694 0 c-401 0 -691 4 -685 9 5
5 43 16 85 25 103 23 115 37 123 146 17 239 18 306 4 373 l-15 68 29 40 28 39
158 0 c88 0 234 3 327 7 l168 6 -7 -54 c-3 -30 -16 -100 -27 -156 -12 -56 -19
-113 -15 -127 19 -77 263 -165 520 -188 143 -13 366 0 505 28 97 20 254 76
303 109 55 37 64 62 49 138 -7 34 -15 121 -18 193 l-6 131 38 7 c29 6 35 10
24 17 -8 5 -25 9 -38 9 -23 0 -24 1 -17 65 10 102 61 280 146 520 90 251 138
414 147 498 5 54 5 57 -16 57 -15 0 -21 -6 -21 -21 0 -13 4 -18 12 -13 8 5 9
2 4 -12 -4 -10 -10 -34 -13 -52 -3 -18 -10 -35 -14 -38 -5 -3 -9 -23 -9 -45 0
-21 -4 -39 -8 -39 -4 0 -8 -15 -9 -32 0 -18 -8 -49 -17 -68 -14 -32 -15 -33
-8 -5 4 17 7 60 7 98 0 37 2 67 5 67 3 0 6 43 7 95 0 52 -2 95 -6 95 -4 0 -14
13 -24 29 -9 16 -33 39 -52 51 -23 14 -32 26 -28 37 5 14 7 13 15 -1 5 -9 13
-14 19 -11z m664 5 c17 -7 14 -9 -13 -9 -20 -1 -31 3 -27 9 7 12 13 12 40 0z
m95 -15 c0 -8 -4 -15 -10 -15 -5 0 -10 -5 -10 -11 0 -6 7 -9 15 -5 8 3 15 1
15 -4 0 -15 -26 -12 -40 5 -10 12 -10 18 0 30 7 8 16 15 21 15 5 0 9 -7 9 -15z
m-118 -77 c-6 -6 -7 0 -4 19 5 21 7 23 10 9 2 -10 0 -22 -6 -28z m58 28 c0 -3
-4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m60 -18 c0
-19 -18 -43 -25 -36 -9 9 4 48 16 48 5 0 9 -6 9 -12z m98 -54 c-6 -16 -12 -30
-13 -30 0 -1 -10 -5 -22 -8 -14 -5 -23 -3 -23 3 0 6 9 11 21 11 12 0 19 5 16
13 -4 12 22 51 30 43 2 -2 -2 -16 -9 -32z m-198 11 c0 -8 -4 -15 -10 -15 -5 0
-7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m58 -20 c14 -31 15 -35 3 -35 -5 0
-11 8 -15 18 -3 9 -9 23 -12 30 -3 6 -2 12 4 12 5 0 14 -11 20 -25z m92 21 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-101
-87 c-16 -10 -23 -4 -14 10 3 6 11 8 17 5 6 -4 5 -9 -3 -15z m161 12 c0 -18
-18 -21 -30 -6 -10 13 -9 15 9 15 11 0 21 -4 21 -9z m-50 -11 c0 -5 -7 -10
-16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m-40 -19 c14 -26
13 -31 -6 -31 -10 0 -14 6 -11 14 3 7 0 19 -6 25 -8 8 -8 11 1 11 6 0 16 -9
22 -19z m-40 -1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m230 -6 c0 -8 -4 -13 -9 -10 -5 3 -12 -5 -15 -19 -6 -24 -36
-36 -36 -15 0 6 7 10 16 10 8 0 13 4 10 9 -9 14 4 41 20 41 8 0 14 -7 14 -16z
m-110 -4 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-3 -47 c-9 -9 -20 -13 -24 -9 -4 4 -1 11 7 16 25 16 35 11 17 -7z m112
-35 c1 -13 -37 -9 -45 4 -5 8 -2 9 9 5 13 -5 17 -1 18 16 0 21 1 21 9 2 5 -11
9 -23 9 -27z m20 -27 c-15 -5 -22 6 -14 19 6 9 10 9 16 -1 5 -8 4 -15 -2 -18z
m-50 -23 c19 -5 51 -8 73 -6 21 2 38 1 38 -2 0 -10 -239 -425 -244 -425 -3 0
-19 22 -37 48 -17 26 -57 68 -88 94 -61 49 -71 63 -47 63 32 0 112 49 152 92
22 24 51 69 64 101 18 44 28 56 39 51 9 -3 31 -11 50 -16z m-904 -183 c-9 -26
-19 -44 -22 -41 -3 3 3 25 13 50 24 58 32 50 9 -9z m-51 -113 c-13 -26 -21
-55 -18 -64 4 -12 0 -18 -11 -18 -12 0 -15 -6 -10 -20 4 -12 0 -26 -8 -35 -13
-12 -17 -12 -33 0 -11 8 -15 15 -10 15 17 0 -17 30 -35 30 -13 0 -19 -8 -20
-25 -2 -44 -4 -47 -26 -62 -22 -15 -22 -14 -11 24 5 21 15 44 20 51 8 9 6 12
-6 12 -9 0 -16 4 -16 10 0 13 46 24 61 15 6 -4 16 -1 21 6 8 11 9 10 4 -3 -3
-11 2 -22 14 -28 11 -6 17 -16 14 -25 -4 -8 -1 -15 6 -15 8 0 10 9 6 25 -5 18
-2 27 9 31 24 10 17 25 -8 19 -18 -5 -24 -2 -24 12 0 20 15 43 29 43 5 0 13
-10 19 -22 8 -20 9 -18 5 10 -4 25 -1 35 14 44 11 7 20 20 20 28 0 12 2 12 9
2 4 -8 -1 -31 -15 -60z m-1954 -8 c0 -8 -16 -63 -35 -123 -48 -147 -130 -282
-267 -435 -36 -41 -47 -48 -67 -41 -21 6 -19 10 41 73 155 162 245 314 278
470 21 98 20 95 35 82 8 -7 15 -19 15 -26z m187 -137 c58 -26 142 -49 224 -62
l35 -5 38 -117 c24 -77 39 -146 43 -198 l6 -81 -289 -2 c-159 -1 -349 -5 -421
-8 -73 -4 -133 -3 -133 1 0 4 23 32 51 63 116 127 206 281 254 434 l27 87 51
-42 c29 -23 80 -55 114 -70z m1659 28 c-3 -8 -1 -15 4 -15 6 0 10 -7 10 -15 0
-8 9 -15 20 -15 21 0 24 -12 10 -45 l-8 -20 -8 20 c-4 11 -18 28 -30 37 -17
14 -25 47 -15 66 5 10 22 -1 17 -13z m866 -186 c66 -24 95 -49 57 -49 -58 0
-216 -109 -250 -172 -21 -40 1 -28 63 34 71 70 133 105 199 114 43 6 46 4 87
-40 50 -54 62 -82 62 -140 0 -80 -12 -97 -105 -145 -46 -24 -108 -65 -138 -91
l-53 -48 -79 3 c-44 1 -131 6 -193 10 -133 9 -131 8 -113 133 24 165 57 239
143 325 52 51 96 75 168 87 53 9 76 6 152 -21z m-3793 -13 c108 -35 181 -144
181 -271 0 -65 -27 -125 -55 -125 -24 0 -18 -13 18 -37 47 -32 97 -51 157 -59
48 -6 51 -8 35 -20 -40 -29 -153 -3 -208 49 -16 15 -30 27 -32 27 -2 0 -10
-18 -19 -39 -15 -39 -71 -111 -86 -111 -4 0 -25 -16 -46 -36 -39 -37 -62 -32
-25 5 22 22 27 41 11 41 -5 0 -6 8 -3 17 6 15 5 16 -9 3 -14 -13 -14 -16 -1
-23 11 -7 9 -13 -12 -31 -17 -15 -24 -28 -19 -39 11 -28 -26 -20 -58 13 -21
22 -38 30 -66 31 -32 0 -34 1 -14 9 12 5 30 6 39 3 10 -4 14 -2 9 5 -3 6 -2
13 4 17 6 4 8 11 4 16 -3 6 -12 3 -20 -8 -12 -15 -15 -16 -23 -3 -9 12 -11 12
-17 -3 -9 -25 -19 -21 -40 18 -22 42 -31 44 -31 9 0 -18 7 -28 23 -34 13 -5
24 -15 24 -21 0 -6 -7 -8 -16 -5 -9 4 -23 1 -32 -6 -13 -11 -14 -10 -8 8 5 16
2 24 -11 28 -10 4 -14 4 -11 0 10 -10 -34 -43 -49 -37 -7 3 -13 0 -13 -6 0 -6
-8 -11 -17 -11 -15 0 -14 2 3 15 10 8 28 15 39 15 16 1 15 3 -6 11 -15 5 -30
19 -35 30 -8 18 -11 18 -29 5 -11 -8 -16 -18 -12 -22 4 -5 12 -2 17 6 11 18
24 6 16 -15 -3 -9 -11 -13 -17 -10 -5 4 -16 -1 -24 -11 -12 -17 -14 -16 -13
14 1 21 8 36 20 43 16 9 17 12 4 24 -16 17 -42 20 -50 6 -8 -12 -46 -21 -46
-10 0 5 7 9 15 9 9 0 18 7 21 15 4 10 13 13 25 10 12 -4 19 -1 19 7 0 8 5 5
11 -6 8 -13 13 -15 19 -6 9 15 1 50 -11 50 -5 0 -9 8 -9 17 0 13 -3 14 -11 6
-6 -6 -18 -9 -25 -6 -10 4 -12 0 -8 -11 3 -9 10 -16 15 -16 5 0 9 -4 9 -10 0
-5 -9 -10 -19 -10 -11 0 -22 -6 -24 -12 -4 -10 -6 -10 -6 -1 -1 6 3 14 8 17
11 7 2 48 -13 59 -7 6 -4 7 7 3 28 -9 47 4 47 33 0 14 -4 21 -8 15 -11 -17
-50 -23 -56 -9 -6 16 -58 17 -53 1 2 -6 14 -10 25 -8 17 3 22 -2 22 -20 0 -13
5 -29 12 -36 8 -8 9 -12 1 -12 -6 0 -14 6 -16 13 -4 9 -8 8 -17 -3 -9 -12 -11
-10 -7 11 4 31 -20 51 -52 42 -12 -3 -21 -1 -21 6 0 6 7 11 15 11 8 0 15 5 15
10 0 6 7 10 15 10 8 0 15 7 15 15 0 8 5 15 11 15 5 0 7 -4 4 -10 -6 -9 10 -14
42 -13 6 0 9 -6 6 -13 -7 -20 0 -17 20 7 12 13 13 19 5 18 -33 -4 -47 2 -45
21 1 23 8 25 25 8 9 -9 12 -9 12 0 0 6 7 9 15 6 19 -8 19 15 1 39 -16 21 -26
22 -26 2 0 -9 -6 -12 -15 -9 -8 4 -15 1 -15 -6 0 -6 -4 -8 -10 -5 -16 10 -12
25 8 25 21 0 46 36 31 46 -6 3 -8 12 -5 20 3 9 -2 14 -14 14 -11 0 -20 -4 -20
-9 0 -6 -12 -3 -27 5 -16 7 -36 14 -45 14 -10 0 -18 5 -18 11 0 6 9 8 20 4 13
-4 20 -2 21 7 1 7 2 18 4 23 1 6 3 16 3 24 1 7 8 11 17 7 8 -3 14 0 12 6 -1 6
4 13 12 16 8 2 11 0 7 -6 -3 -5 1 -12 8 -15 10 -4 17 3 21 22 8 37 72 63 91
37 10 -15 12 -15 18 -1 3 8 0 15 -7 15 -7 0 -4 5 6 11 10 6 29 8 42 5 13 -3
27 -1 31 5 11 18 297 6 363 -15z m2927 -43 c-10 -10 -19 5 -10 18 6 11 8 11
12 0 2 -7 1 -15 -2 -18z m-1123 -133 c8 0 8 -3 0 -11 -8 -8 -17 -7 -31 2 -20
13 -20 14 -4 32 17 19 17 19 20 -2 2 -11 8 -21 15 -21z m1057 25 c0 -8 -4 -15
-10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-1127 -2 c-7 -2
-13 -11 -13 -19 0 -9 -4 -12 -10 -9 -19 12 -10 35 13 34 12 0 16 -3 10 -6z
m1167 -8 c0 -8 -7 -15 -15 -15 -9 0 -12 6 -9 15 4 8 10 15 15 15 5 0 9 -7 9
-15z m-1242 -8 c15 6 15 5 3 -11 -13 -16 -13 -17 0 -15 21 3 50 -12 43 -23 -4
-7 -9 -6 -14 1 -9 15 -40 3 -40 -15 0 -8 -4 -14 -10 -14 -14 0 -13 13 2 28 8
8 9 15 3 20 -6 4 -11 16 -12 27 -1 11 0 15 3 8 3 -7 13 -9 22 -6z m-2275 -19
c27 5 20 -15 -8 -22 -16 -4 -34 -1 -46 8 -20 13 -20 14 -2 18 10 3 23 2 28 -1
6 -3 18 -4 28 -3z m2397 -27 c5 -10 5 -23 -1 -32 -7 -12 -9 -11 -9 2 0 9 -7
22 -15 29 -8 7 -15 16 -15 21 0 13 30 -2 40 -20z m-2340 -2 c0 -24 -23 -21
-28 4 -2 10 3 17 12 17 10 0 16 -9 16 -21z m4651 -5 c48 -30 129 -103 129
-115 0 -15 -73 20 -105 50 -16 16 -40 34 -52 40 -13 7 -23 21 -23 31 0 25 2
25 51 -6z m-4786 6 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6
0 7 -4 4 -10z m72 -23 c6 2 14 -1 18 -6 3 -6 1 -11 -4 -11 -6 0 -11 -7 -11
-15 0 -8 -4 -15 -10 -15 -5 0 -10 6 -10 14 0 8 -7 16 -15 20 -8 3 -15 10 -15
16 0 7 6 8 18 2 9 -5 22 -7 29 -5z m-50 -36 c8 -7 11 -16 8 -19 -7 -8 -34 30
-34 48 1 13 1 13 6 0 4 -8 12 -21 20 -29z m3563 29 c0 -5 -2 -10 -4 -10 -3 0
-8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-1145 -7 c-11 -3 -25 -9 -32
-15 -7 -5 -13 -5 -17 1 -7 10 16 19 49 19 l20 0 -20 -5z m-2455 -7 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m2380 -6 c0
-5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m120
-24 c0 -8 -12 -11 -35 -8 -19 2 -32 8 -29 14 3 5 12 7 20 4 7 -3 16 -1 19 5 8
12 25 2 25 -15z m2293 0 l29 -23 -43 -11 c-24 -6 -45 -10 -46 -8 -5 5 19 66
25 66 4 -1 20 -11 35 -24z m-4675 2 c-2 -6 -10 -14 -16 -16 -7 -2 -10 2 -6 12
7 18 28 22 22 4z m2254 -20 c-6 -6 -15 -8 -19 -4 -4 4 -1 11 7 16 19 12 27 3
12 -12z m48 2 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-997 -14 c47 -19 123 -108 146 -172 36 -94 44 -182 32 -318 -7
-66 -13 -141 -15 -165 -2 -25 -11 -50 -19 -56 -33 -24 -220 -60 -307 -58 -131
1 -270 64 -337 151 -25 34 -28 44 -28 117 0 69 4 89 31 146 25 51 42 73 79 98
26 18 48 31 49 29 1 -2 8 -21 15 -43 29 -86 160 -226 210 -224 11 0 7 7 -13
22 -100 74 -155 144 -181 230 -12 39 -11 46 15 100 53 111 119 156 228 157 34
0 77 -6 95 -14z m971 -27 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15
3 5 -3 7 -10 3 -15z m-34 1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m2559 -25 c53 -26 118 -95 128 -133 8 -35 -17 -81
-88 -157 -102 -109 -210 -176 -322 -200 -30 -6 -28 -25 4 -25 35 0 127 37 183
75 79 53 148 116 202 188 25 34 51 62 57 62 20 0 74 -82 80 -122 15 -90 -60
-260 -149 -343 -82 -74 -142 -94 -289 -94 -134 0 -202 17 -367 93 -47 22 -111
47 -141 56 l-56 16 77 102 c42 56 96 138 120 183 72 135 197 243 342 296 96
35 156 36 219 3z m-2567 -17 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1
-11 -7 -17z m52 -17 c-6 -6 -24 19 -24 34 1 6 7 1 15 -9 8 -11 12 -22 9 -25z
m-2324 -10 c0 -5 -7 -12 -16 -15 -14 -5 -15 -4 -4 9 14 17 20 19 20 6z m1538
-23 c-21 -31 -33 -32 -46 -3 -11 24 -10 25 24 25 35 0 36 -1 22 -22z m747 12
c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m-20 -30 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m45 -24 c-7 -7 -13
-7 -20 0 -6 6 -3 10 10 10 13 0 16 -4 10 -10z m9 -28 c7 13 16 20 22 17 14 -9
11 -25 -6 -25 -7 0 -19 -8 -24 -17 -6 -10 -11 -12 -11 -5 0 6 -4 12 -9 12 -10
0 -9 -19 2 -37 5 -8 3 -13 -3 -13 -7 0 -14 9 -17 20 -3 11 -12 20 -19 20 -8 0
-14 5 -14 10 0 6 6 10 14 10 10 0 12 7 8 23 -5 20 -3 19 20 -7 l25 -30 12 22z
m-2254 12 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z m796 -50 c10 -6 19 -17 19 -25 0 -23 -16 -18 -34 10 -18 27 -15 30 15 15z
m1492 -4 c4 -10 1 -13 -9 -9 -15 6 -19 23 -5 23 5 0 11 -7 14 -14z m1967 -4
c0 -2 -11 -18 -24 -35 -13 -18 -38 -59 -55 -93 -18 -33 -66 -105 -107 -160
-53 -71 -74 -105 -71 -121 3 -18 -1 -23 -17 -23 -11 0 -45 -3 -75 -7 l-54 -6
55 74 c48 64 165 245 223 346 18 31 22 32 72 30 29 -1 53 -3 53 -5z m-4225
-12 c-4 -6 -11 -8 -16 -5 -5 4 -9 2 -9 -4 0 -5 6 -12 13 -14 7 -3 5 -6 -5 -6
-18 -1 -24 19 -11 32 10 11 35 8 28 -3z m2169 -15 c4 -14 11 -23 16 -20 6 3
10 1 10 -4 0 -6 7 -11 15 -11 18 0 19 -11 2 -34 -10 -14 -18 -15 -40 -7 -17 7
-27 7 -27 1 0 -6 4 -10 9 -10 4 0 6 -11 3 -25 -5 -30 -2 -31 28 -5 13 11 29
20 36 20 20 0 28 -21 13 -31 -12 -7 -12 -9 0 -9 7 0 11 -6 8 -13 -3 -8 3 -20
14 -28 23 -16 25 -29 4 -29 -8 0 -15 5 -15 10 0 6 -5 10 -11 10 -8 0 -8 -4 -1
-13 7 -8 7 -18 2 -27 -17 -28 -29 12 -16 55 4 11 2 26 -3 33 -7 12 -12 12 -32
0 -13 -7 -32 -22 -42 -33 -19 -20 -19 -20 -3 8 17 28 10 37 -12 15 -9 -9 -12
-8 -12 5 0 9 5 17 11 17 8 0 10 13 7 38 -5 32 -3 38 13 39 11 1 19 -2 19 -8 0
-5 5 -9 10 -9 17 0 11 25 -10 48 -21 22 -26 42 -11 42 5 0 12 -11 15 -25z m74
-2 c-2 -10 -11 -19 -21 -21 -16 -3 -17 -1 -5 17 16 25 31 27 26 4z m-88 -17
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-1910 -20 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2
10 -4z m34 -38 c-5 -8 -10 -5 -14 8 -10 25 0 41 12 20 5 -9 6 -21 2 -28z m-58
18 c8 -8 11 -17 6 -20 -4 -2 -13 4 -20 15 -14 22 -7 25 14 5z m104 10 c0 -3
-4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m30 -20 c0
-22 -59 -48 -79 -35 -13 8 -11 9 8 3 16 -4 21 -3 17 5 -4 7 1 11 13 11 12 0
21 7 21 15 0 8 5 15 10 15 6 0 10 -6 10 -14z m1905 4 c-3 -5 1 -10 9 -10 9 0
16 -4 16 -9 0 -5 -11 -8 -25 -8 -14 0 -28 3 -31 8 -8 13 5 29 22 29 8 0 12 -4
9 -10z m88 -46 c-4 -11 -9 -12 -16 -5 -7 7 -14 7 -23 0 -8 -6 -14 -7 -18 0 -4
5 4 17 16 25 25 18 51 5 41 -20z m-2113 6 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15
10 0 2 7 4 15 4 8 0 15 -4 15 -10z m2057 -36 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m48 -4 c-3 -5 -13 -10 -21 -10 -8 0 -14 5 -14 10 0 6 9
10 21 10 11 0 17 -4 14 -10z m-135 -26 c0 -8 -5 -14 -11 -14 -6 0 -9 -7 -5
-15 6 -16 -9 -21 -18 -6 -9 14 12 63 24 56 5 -3 10 -13 10 -21z m149 -5 c1 16
14 7 17 -12 1 -7 -7 -14 -17 -14 -11 -1 -19 2 -19 8 0 5 -12 9 -27 9 -16 0
-23 4 -17 9 17 16 49 17 56 1 5 -12 7 -12 7 -1z m-79 -59 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1740 -215 c0 -3 -11 -23
-25 -45 l-25 -40 -398 1 c-229 0 -386 4 -372 9 21 7 282 34 540 55 30 3 87 9
125 13 85 11 155 14 155 7z m-1891 -129 c-2 -2 -183 -21 -402 -42 l-399 -38
-29 34 c-16 19 -29 38 -29 43 0 4 194 7 432 7 237 0 429 -2 427 -4z"
        />
        <path d="M2381 8634 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M2466 8469 c-11 -11 -14 -19 -8 -19 6 0 14 5 17 10 4 6 11 8 16 5 5
-4 9 -2 9 4 0 19 -15 18 -34 0z"
        />
        <path
          d="M2405 8430 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M2446 8425 c-11 -8 -18 -17 -15 -19 2 -2 15 4 28 15 27 22 15 26 -13
4z"
        />
        <path
          d="M2540 8419 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path d="M2381 8384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M2615 8380 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M2475 8340 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M2705 8340 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path
          d="M2569 8305 c-1 -3 -3 -9 -4 -15 -1 -5 -3 -15 -4 -21 0 -5 -5 -8 -10
-5 -5 3 -11 0 -14 -7 -3 -9 2 -13 16 -12 17 1 22 8 23 33 1 28 -3 43 -7 27z"
        />
        <path
          d="M2755 8300 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M2790 8299 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M2680 8289 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M2622 8271 c-11 -7 -10 -10 3 -15 9 -3 24 -1 33 5 16 10 16 10 0 5
-9 -3 -18 -1 -20 4 -2 5 -9 6 -16 1z"
        />
        <path
          d="M2480 8231 c0 -8 -8 -20 -17 -27 -10 -7 -13 -13 -8 -13 6 0 19 6 29
14 16 12 17 18 8 27 -9 9 -12 9 -12 -1z"
        />
        <path d="M2701 8194 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M2821 8200 c-1 -3 -4 -13 -6 -22 -2 -10 -10 -25 -19 -34 -20 -20 -21
-31 -1 -14 12 10 15 10 15 -1 0 -9 3 -10 11 -2 6 6 9 26 6 44 -2 19 -5 32 -6
29z"
        />
        <path
          d="M2628 8183 c10 -4 10 -8 1 -19 -9 -11 -8 -14 5 -14 9 0 16 8 16 20 0
13 -6 20 -17 19 -12 0 -13 -2 -5 -6z"
        />
        <path
          d="M2430 8154 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"
        />
        <path
          d="M2500 8160 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"
        />
        <path
          d="M2540 8159 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M2615 8120 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M2640 8123 c0 -5 6 -16 13 -25 15 -19 31 -12 33 15 1 12 -5 17 -22
17 -13 0 -24 -3 -24 -7z"
        />
        <path
          d="M2730 8109 c0 -6 -8 -18 -17 -26 -16 -12 -14 -12 10 -2 28 13 34 24
17 34 -5 3 -10 1 -10 -6z"
        />
        <path
          d="M2505 8090 c-8 -13 3 -30 18 -29 9 0 9 2 0 5 -7 3 -9 12 -6 20 6 16
-3 19 -12 4z"
        />
        <path d="M2451 8054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M2878 8063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M2569 8055 c-3 -5 -6 -26 -8 -45 -2 -26 0 -32 9 -24 6 6 10 27 8 45
-2 19 -6 30 -9 24z"
        />
        <path d="M2668 8045 c13 -9 26 -14 29 -11 8 8 3 12 -27 21 l-25 7 23 -17z" />
        <path
          d="M2747 8039 c-9 -10 -12 -19 -6 -19 15 0 41 28 31 34 -5 3 -16 -4 -25
-15z"
        />
        <path
          d="M2795 8050 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M2870 8037 c0 -13 53 -67 66 -67 5 0 34 -18 65 -40 42 -29 59 -36 67
-28 16 16 112 -51 268 -183 65 -56 164 -138 219 -182 273 -220 391 -339 462
-464 19 -35 41 -63 49 -63 19 0 18 3 -10 59 -56 110 -129 190 -326 354 -80 67
-197 167 -262 223 -222 195 -361 297 -498 365 -83 42 -100 46 -100 26z"
        />
        <path d="M2620 8010 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z" />
        <path d="M2536 7993 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
        <path
          d="M2735 8000 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M2754 7969 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16 11z" />
        <path
          d="M1364 7955 c-12 -31 55 -110 391 -455 78 -80 176 -183 219 -230 l78
-85 -68 90 c-108 142 -245 315 -407 513 -132 161 -152 182 -178 182 -17 0 -32
-6 -35 -15z"
        />
        <path
          d="M2720 7959 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path d="M2650 7950 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z" />
        <path
          d="M2821 7940 c-1 -3 -4 -13 -6 -23 -2 -10 -12 -25 -22 -34 -16 -13 -16
-14 0 -9 9 3 17 0 17 -6 0 -9 3 -9 11 -1 6 6 9 26 6 44 -2 19 -5 32 -6 29z"
        />
        <path d="M2633 7923 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
        <path
          d="M2985 7910 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M2625 7900 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path
          d="M2895 7890 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path
          d="M2980 7879 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M2640 7862 c0 -12 22 -32 35 -32 6 0 11 9 11 20 0 14 -7 20 -23 20
-13 0 -23 -4 -23 -8z"
        />
        <path
          d="M2730 7850 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M2875 7810 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"
        />
        <path
          d="M2875 7780 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path d="M2917 7753 c-12 -11 -8 -43 6 -43 6 0 12 11 12 25 0 25 -6 31 -18 18z" />
        <path
          d="M3100 7699 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3195 7670 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3280 7629 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3165 7620 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3110 7590 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M3340 7575 c-19 -23 -8 -25 14 -4 11 11 15 19 9 19 -6 0 -16 -7 -23
-15z"
        />
        <path d="M3471 7564 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M3445 7550 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3385 7520 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3299 7515 c-1 -3 -3 -9 -4 -15 -1 -5 -3 -16 -4 -22 -1 -9 -4 -8 -11
2 -8 12 -10 12 -10 -5 0 -14 7 -20 23 -21 19 -1 22 4 23 32 0 19 -3 34 -8 34
-4 0 -9 -2 -9 -5z"
        />
        <path
          d="M3525 7510 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path
          d="M3410 7499 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3245 7480 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M3347 7468 c-7 -5 -2 -8 15 -8 15 0 29 4 32 8 6 10 -31 10 -47 0z" />
        <path d="M3221 7434 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M3457 7433 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
        />
        <path d="M3363 7373 c0 -25 0 -25 13 -8 13 18 11 35 -5 35 -5 0 -8 -12 -8 -27z" />
        <path
          d="M3549 7395 c-1 -3 -3 -9 -4 -15 -1 -5 -3 -15 -4 -21 0 -5 -5 -7 -11
-4 -5 3 -10 0 -10 -7 0 -17 40 -21 41 -5 1 7 3 22 4 35 2 18 -12 33 -16 17z"
        />
        <path
          d="M3485 7360 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path
          d="M3630 7349 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path d="M3385 7330 c-7 -12 12 -40 26 -40 5 0 8 11 7 25 -2 25 -21 34 -33 15z" />
        <path
          d="M3613 7255 c0 -13 3 -22 8 -19 5 3 9 14 9 25 0 10 -4 19 -9 19 -5 0
-8 -11 -8 -25z"
        />
        <path
          d="M3485 7171 c-6 -11 13 -26 22 -17 4 3 0 6 -8 6 -11 0 -11 2 1 10 9 6
10 10 3 10 -6 0 -14 -4 -18 -9z"
        />
        <path d="M3718 7113 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3715 7090 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"
        />
        <path
          d="M2266 6890 c5 -8 42 -67 84 -130 42 -63 154 -239 249 -390 96 -151
178 -279 183 -285 30 -32 -67 144 -175 315 -98 157 -374 554 -341 490z"
        />
        <path d="M4420 6830 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path d="M4540 6780 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z" />
        <path
          d="M4515 6740 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4485 6730 c-4 -6 -16 -8 -28 -4 -19 6 -20 5 -8 -10 17 -21 24 -20
39 4 7 11 10 20 8 20 -3 0 -8 -5 -11 -10z"
        />
        <path d="M4326 6721 c-3 -5 5 -11 17 -13 15 -3 24 0 24 9 0 15 -33 18 -41 4z" />
        <path
          d="M4450 6670 c-12 -8 -11 -10 6 -10 11 0 26 -6 33 -12 11 -10 10 -8 0
10 -16 25 -18 26 -39 12z"
        />
        <path d="M4405 6639 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z" />
        <path d="M4370 6615 c0 -10 29 -24 34 -17 3 6 -3 12 -14 15 -11 3 -20 4 -20 2z" />
        <path
          d="M4305 6590 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4350 6573 c0 -19 21 -27 33 -13 9 10 9 11 -2 5 -8 -4 -17 -2 -22 6
-6 9 -9 10 -9 2z"
        />
        <path d="M4411 6544 c13 -16 29 -19 29 -5 0 5 -9 11 -21 14 -16 4 -18 3 -8 -9z" />
        <path
          d="M4490 6556 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"
        />
        <path
          d="M4315 6530 c-3 -5 3 -10 14 -10 11 0 27 -10 35 -22 9 -12 16 -17 16
-10 0 19 -4 27 -13 24 -4 -1 -10 5 -13 13 -7 17 -30 20 -39 5z"
        />
        <path
          d="M4585 6490 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3585 6480 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4436 6482 c-3 -4 10 -13 27 -20 27 -10 29 -9 15 2 -10 7 -15 16 -11
19 3 4 -1 7 -10 7 -9 0 -18 -4 -21 -8z"
        />
        <path
          d="M4270 6459 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4395 6440 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M4240 6420 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path
          d="M3585 6390 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3830 6371 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"
        />
        <path
          d="M3570 6339 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3550 6320 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M3665 6310 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4359 6303 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"
        />
        <path
          d="M3665 6280 c-4 -6 -11 -8 -16 -5 -5 4 -9 -1 -9 -9 0 -14 3 -14 20 -4
11 7 20 16 20 20 0 11 -8 10 -15 -2z"
        />
        <path d="M3608 6273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3750 6269 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3835 6270 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3827 6233 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
        />
        <path
          d="M3665 6220 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3665 6190 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"
        />
        <path
          d="M3915 6190 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3605 6180 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"
        />
        <path d="M3855 6160 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
        <path
          d="M3910 6159 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3769 6153 c-1 -5 -1 -12 0 -16 1 -4 -6 -10 -14 -13 -23 -9 -18 -29
9 -30 21 -2 23 3 23 32 -1 19 -5 34 -9 34 -4 0 -9 -3 -9 -7z"
        />
        <path
          d="M3886 6145 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"
        />
        <path
          d="M3995 6150 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path
          d="M3824 6124 c4 -12 2 -15 -9 -10 -8 3 -15 1 -15 -4 0 -11 57 -7 68 5
4 5 1 5 -6 1 -7 -4 -15 -1 -18 8 -3 9 -11 16 -16 16 -6 0 -7 -7 -4 -16z"
        />
        <path
          d="M3915 6100 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M3605 6090 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z" />
        <path
          d="M3672 6088 c-7 -7 -12 -17 -12 -22 0 -6 5 -3 11 8 9 15 13 16 20 5 7
-11 9 -10 9 4 0 20 -11 22 -28 5z"
        />
        <path
          d="M3912 6068 c-7 -7 -12 -8 -12 -2 0 5 -5 2 -10 -6 -6 -10 -10 -11 -10
-2 0 6 -7 12 -15 12 -8 0 -15 -4 -15 -10 0 -9 6 -11 43 -14 6 -1 16 -5 20 -11
5 -6 7 -3 3 8 -3 11 0 17 9 17 7 0 17 5 20 10 9 14 -19 12 -33 -2z"
        />
        <path
          d="M3779 6065 c-1 -3 -2 -8 -3 -12 -1 -5 -5 -14 -10 -22 -7 -11 -10 -11
-17 0 -6 10 -8 8 -7 -6 1 -13 10 -20 23 -21 18 -1 22 4 23 32 1 19 -1 34 -3
34 -3 0 -6 -2 -6 -5z"
        />
        <path
          d="M4000 6059 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path d="M3716 6031 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
        <path
          d="M3823 6025 c-10 -11 -9 -15 8 -20 13 -5 25 -2 30 6 17 28 -16 40 -38
14z"
        />
        <path d="M4008 6006 c-24 -18 -22 -34 4 -32 14 1 22 9 24 24 4 25 -3 27 -28 8z" />
        <path
          d="M4107 6003 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
        />
        <path
          d="M3850 5965 c0 -8 -3 -15 -7 -15 -5 0 -9 -11 -9 -24 -1 -17 2 -23 12
-19 7 3 12 10 11 16 -1 7 6 11 16 9 12 -2 17 4 17 23 0 18 -5 25 -20 25 -11 0
-20 -7 -20 -15z"
        />
        <path d="M3936 5967 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
        <path d="M3966 5911 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
        <path
          d="M3447 9913 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
        />
        <path d="M3448 9863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3440 9768 c-12 -22 -12 -22 6 -6 10 10 15 20 12 24 -4 3 -12 -5 -18
-18z"
        />
        <path
          d="M3545 9750 c-4 -6 -3 -16 3 -22 13 -13 23 4 13 21 -7 10 -10 10 -16
1z"
        />
        <path d="M3538 9703 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3490 9650 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M3543 9610 c-11 -16 -10 -18 2 -14 8 4 15 10 15 16 0 5 5 6 12 2 7
-4 8 -3 4 4 -10 17 -19 15 -33 -8z"
        />
        <path d="M3671 8464 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M3675 8310 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3695 8280 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3645 8240 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path
          d="M3635 8180 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M3596 8155 c4 -8 10 -12 15 -9 14 8 10 24 -6 24 -9 0 -12 -6 -9 -15z" />
        <path d="M3668 8153 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3815 8150 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path d="M3891 8134 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M3648 8103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3920 8099 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3575 8090 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3675 8091 c-13 -12 -3 -20 14 -12 10 6 21 7 25 4 3 -3 6 -1 6 5 0
10 -36 13 -45 3z"
        />
        <path
          d="M3895 8090 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3730 8070 c0 -5 -7 -7 -15 -4 -8 4 -15 1 -15 -4 0 -17 41 -26 51
-11 13 20 11 29 -6 29 -8 0 -15 -4 -15 -10z"
        />
        <path
          d="M3843 8069 c9 -6 14 -15 11 -21 -4 -7 -2 -8 5 -4 14 9 -3 36 -23 36
-6 0 -3 -5 7 -11z"
        />
        <path d="M3780 8039 c0 -5 12 -9 27 -9 30 0 27 9 -6 15 -11 2 -21 0 -21 -6z" />
        <path d="M3928 8033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3695 8000 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M3845 8000 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
        <path
          d="M3820 7991 c0 -5 -14 -13 -31 -16 -21 -5 -27 -10 -19 -15 9 -6 8 -10
-6 -19 -11 -6 -13 -11 -6 -11 7 0 10 -5 7 -10 -4 -6 -11 -8 -16 -5 -16 10 -10
-4 13 -30 25 -29 38 -32 38 -10 0 13 7 15 35 9 22 -4 35 -2 35 5 0 6 -7 8 -15
5 -8 -4 -15 -2 -15 4 0 5 -11 7 -25 4 -20 -3 -25 0 -25 16 0 12 5 24 11 28 8
4 7 9 -2 15 -10 6 -8 9 9 9 13 0 22 6 22 15 0 8 -2 15 -5 15 -3 0 -5 -4 -5 -9z"
        />
        <path
          d="M3650 7986 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"
        />
        <path d="M3868 7963 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3955 7960 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3925 7950 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3600 7917 c0 -16 20 -37 35 -37 5 0 3 5 -5 10 -10 6 -11 10 -2 10 6
0 12 4 12 10 0 5 -7 6 -17 3 -11 -4 -14 -3 -9 5 4 7 2 12 -3 12 -6 0 -11 -6
-11 -13z"
        />
        <path
          d="M3886 7905 c-3 -8 -1 -15 3 -15 5 0 11 7 15 15 3 8 1 15 -3 15 -5 0
-11 -7 -15 -15z"
        />
        <path
          d="M3943 7903 c5 -8 -17 -34 -28 -33 -17 1 -18 -1 -5 -10 22 -14 40 -12
40 5 0 9 7 18 15 21 18 7 10 24 -11 24 -8 0 -13 -3 -11 -7z"
        />
        <path
          d="M3840 7830 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3915 7830 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3808 7813 c9 -4 10 -9 2 -18 -8 -9 -6 -14 7 -19 13 -5 14 -9 5 -15
-14 -8 -5 -71 10 -71 5 0 6 5 3 10 -3 6 -2 10 3 10 14 0 22 38 8 44 -8 3 -7 7
2 14 11 7 11 10 -2 15 -9 4 -16 13 -16 22 0 8 -8 15 -17 14 -12 0 -13 -2 -5
-6z"
        />
        <path d="M3928 7753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M3878 7633 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3865 7610 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"
        />
        <path
          d="M8434 5691 c-59 -36 -91 -152 -61 -224 44 -104 195 -99 247 8 34 71
21 177 -27 215 -34 27 -116 27 -159 1z m129 -22 c71 -32 60 -183 -15 -229
-108 -65 -197 48 -139 176 11 23 27 47 38 53 24 14 86 14 116 0z"
        />
        <path
          d="M7335 5806 c-34 -34 -41 -86 -20 -162 17 -61 17 -98 0 -136 -6 -15
-2 -18 31 -18 22 0 56 3 77 7 36 5 38 8 31 32 -4 14 -8 77 -8 139 -1 112 -2
114 -29 138 -35 30 -52 30 -82 0z m70 -28 c12 -19 14 -40 9 -108 -4 -54 -2
-96 5 -114 6 -16 10 -30 9 -31 -2 -2 -22 -6 -45 -10 l-42 -7 11 30 c9 23 8 42
-5 89 -21 74 -21 106 -2 143 18 36 38 38 60 8z"
        />
        <path
          d="M7266 5425 c-38 -38 -28 -138 21 -222 20 -34 24 -53 20 -84 -4 -32
-2 -39 11 -39 27 0 116 37 120 49 2 6 -8 25 -22 42 -21 25 -26 41 -26 87 0 79
-15 126 -51 156 -35 30 -51 32 -73 11z m67 -47 c15 -20 22 -50 27 -105 6 -76
21 -123 40 -123 23 0 7 -18 -25 -27 -33 -10 -35 -10 -35 11 0 12 -13 48 -29
81 -35 74 -45 116 -36 161 7 42 28 42 58 2z"
        />
        <path
          d="M7321 4934 c-29 -37 -35 -65 -26 -127 8 -58 10 -141 4 -172 -4 -19 1
-20 76 -20 80 0 80 0 76 25 -5 28 -5 81 0 187 3 60 1 76 -17 100 -30 41 -84
44 -113 7z m96 -38 c14 -31 15 -43 3 -108 -14 -82 -15 -94 -5 -125 6 -19 2
-22 -39 -28 -26 -4 -49 -5 -52 -3 -2 3 1 20 7 38 9 24 9 45 0 82 -6 27 -11 65
-11 84 0 88 64 128 97 60z"
        />
        <path
          d="M7292 4417 c-37 -40 -76 -138 -80 -204 -5 -75 18 -155 55 -195 25
-26 25 -27 9 -68 -25 -62 -22 -70 25 -70 46 0 146 26 155 41 3 5 -8 27 -25 48
-37 46 -38 59 -9 114 45 85 21 240 -49 318 -41 45 -52 47 -81 16z m62 -36 c22
-25 52 -104 61 -162 5 -37 2 -59 -19 -111 -32 -78 -32 -107 -5 -142 15 -20 18
-31 11 -38 -12 -12 -112 -24 -112 -14 0 4 7 23 15 43 15 35 15 39 -5 63 -46
58 -55 83 -55 165 0 80 10 120 47 183 22 37 38 40 62 13z"
        />
        <path d="M3710 5836 c0 -8 4 -17 9 -21 6 -3 9 4 8 15 -1 23 -17 28 -17 6z" />
        <path
          d="M7962 5668 c-18 -18 -14 -53 8 -73 26 -23 41 -10 42 35 1 41 -26 62
-50 38z"
        />
        <path
          d="M8820 5073 c0 -19 166 -57 206 -47 43 11 19 29 -47 35 -35 4 -85 10
-111 14 -30 5 -48 4 -48 -2z"
        />
        <path
          d="M8853 4822 c4 -81 14 -196 22 -257 8 -60 29 -213 45 -340 40 -300 65
-454 76 -465 11 -11 124 -29 141 -23 18 7 17 13 -16 271 -30 226 -54 519 -67
788 -3 78 -9 146 -13 151 -3 6 -48 13 -100 16 l-94 6 6 -147z"
        />
        <path
          d="M8980 3697 c0 -9 22 -20 61 -32 83 -23 169 -31 169 -16 0 14 -2 15
-112 35 -47 9 -93 19 -102 22 -10 4 -16 1 -16 -9z"
        />
        <path
          d="M9944 5202 c-32 -20 -23 -85 17 -128 30 -32 40 -78 21 -98 -19 -19
-11 -30 17 -21 33 10 27 63 -14 125 -37 55 -46 100 -20 100 8 0 15 -5 15 -12
0 -9 3 -9 13 1 10 10 8 15 -7 27 -22 16 -27 17 -42 6z"
        />
        <path
          d="M9840 5140 c-12 -7 -15 -31 -14 -115 1 -65 5 -110 13 -118 8 -8 11 3
11 47 0 65 12 70 45 18 11 -17 26 -31 33 -32 16 0 15 4 -8 50 -13 25 -20 56
-18 86 1 35 -3 50 -17 60 -22 16 -25 17 -45 4z m38 -43 c2 -9 -4 -27 -12 -39
-16 -22 -16 -21 -16 21 0 30 4 41 13 39 6 -3 13 -12 15 -21z"
        />
        <path
          d="M9719 5063 c-21 -60 -18 -167 5 -192 47 -52 82 3 73 115 -8 105 -53
149 -78 77z m45 -19 c10 -25 7 -162 -3 -169 -25 -15 -40 78 -25 154 7 34 18
40 28 15z"
        />
        <path
          d="M9656 4945 c4 -58 3 -95 -3 -95 -5 0 -19 32 -32 70 -12 39 -26 70
-32 70 -15 0 -10 -31 19 -105 21 -54 32 -70 47 -70 19 0 20 8 23 113 2 96 0
112 -13 112 -13 0 -14 -13 -9 -95z"
        />
        <path
          d="M9485 4939 c-9 -29 5 -219 16 -219 5 0 9 17 9 38 0 60 41 86 60 37 5
-14 14 -25 21 -25 14 0 4 49 -22 110 -34 76 -71 102 -84 59z m50 -49 c17 -32
13 -50 -11 -50 -10 0 -14 12 -14 40 0 22 2 40 5 40 2 0 12 -14 20 -30z"
        />
        <path
          d="M9380 4863 c0 -70 18 -165 32 -174 16 -10 48 8 48 27 0 11 -4 12 -19
5 -23 -13 -26 -2 -37 107 -8 83 -24 107 -24 35z"
        />
        <path
          d="M9303 4885 c-31 -13 -33 -17 -32 -67 1 -83 22 -201 38 -206 10 -3 12
5 6 39 -3 24 -9 52 -11 61 -3 11 5 28 20 43 32 31 34 50 6 35 -28 -15 -40 -5
-40 34 0 29 4 34 32 42 17 5 33 15 36 22 5 16 -15 15 -55 -3z"
        />
        <path
          d="M9706 4761 c-15 -16 -17 -30 -11 -70 4 -27 13 -58 21 -68 12 -16 13
-15 14 15 0 23 8 40 28 57 25 22 25 24 7 25 -12 0 -26 -5 -32 -11 -21 -21 -25
12 -4 35 12 12 21 25 21 29 0 14 -27 6 -44 -12z"
        />
        <path
          d="M9614 4695 c-27 -42 -4 -115 37 -115 41 0 48 109 8 130 -25 14 -26
13 -45 -15z m46 -46 c0 -52 -24 -48 -28 4 -2 28 1 37 12 37 12 0 16 -11 16
-41z"
        />
        <path
          d="M9927 4693 c-38 -27 -50 -56 -20 -51 13 2 19 -10 29 -55 6 -32 14
-61 18 -64 14 -15 16 9 5 76 -11 63 -10 73 5 89 35 40 15 42 -37 5z"
        />
        <path
          d="M9822 4618 c-13 -13 -16 -58 -6 -107 9 -41 26 -40 23 2 -4 35 11 39
44 10 23 -22 35 -8 13 16 -12 13 -15 27 -11 41 12 39 -34 67 -63 38z m38 -22
c0 -15 -18 -31 -26 -23 -7 7 7 37 17 37 5 0 9 -6 9 -14z"
        />
        <path
          d="M9770 4515 c0 -64 -17 -73 -26 -15 -8 46 -24 54 -24 11 0 -24 -4 -28
-19 -24 -22 6 -39 -16 -21 -27 9 -6 9 -12 1 -29 -14 -25 -29 -27 -38 -5 -6 15
-2 29 22 87 13 30 -14 19 -30 -14 -20 -37 -16 -88 8 -103 23 -15 48 1 66 42
14 30 14 30 31 10 9 -12 22 -18 28 -14 18 11 35 81 28 110 -11 45 -26 28 -26
-29z"
        />
        <path
          d="M9470 4476 c0 -33 -17 -42 -41 -20 -32 29 -32 9 -1 -28 17 -19 34
-52 39 -77 4 -25 15 -47 22 -49 10 -3 12 1 8 19 -4 13 -7 59 -7 102 0 44 -4
77 -10 77 -5 0 -10 -11 -10 -24z"
        />
        <path
          d="M9540 4480 c-36 -36 -21 -140 20 -140 24 0 40 34 40 85 0 44 -4 56
-20 65 -15 8 -24 6 -40 -10z m38 -55 c4 -43 -13 -69 -28 -45 -11 17 -13 74 -3
84 15 14 28 -2 31 -39z"
        />
        <path
          d="M9854 4134 c-32 -26 -103 -76 -156 -111 -90 -59 -115 -83 -87 -83 11
0 254 163 297 199 22 19 30 41 14 41 -5 0 -35 -21 -68 -46z"
        />
        <path
          d="M9783 3985 c-71 -54 -89 -72 -80 -81 6 -6 170 113 175 127 8 22 -28
4 -95 -46z"
        />
        <path
          d="M2426 5519 c-60 -47 -209 -294 -411 -679 -201 -383 -623 -1255 -612
-1266 15 -15 419 685 944 1636 182 329 169 301 147 317 -25 18 -37 16 -68 -8z"
        />
        <path
          d="M1249 3184 c-44 -54 142 -323 780 -1124 304 -382 421 -523 428 -516
15 15 -430 653 -1041 1494 -119 163 -138 180 -167 146z"
        />
        <path
          d="M4167 4531 c-90 -96 -145 -253 -133 -378 8 -76 14 -93 33 -93 11 0
13 5 8 18 -52 124 -1 319 115 443 28 29 50 55 50 56 0 2 -6 3 -14 3 -7 0 -34
-22 -59 -49z"
        />
        <path
          d="M7997 4413 c-12 -12 -7 -60 8 -73 18 -15 31 -7 37 24 8 40 -22 72
-45 49z"
        />
        <path d="M4606 3793 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
        <path
          d="M4553 3737 c-15 -20 -19 -33 -12 -38 8 -5 8 -12 0 -27 -16 -30 -13
-37 7 -19 10 10 22 14 25 10 4 -3 7 6 7 21 0 22 -4 27 -16 22 -12 -5 -15 -2
-10 10 3 8 6 19 6 22 0 4 5 0 10 -8 7 -12 12 -12 22 -2 9 9 9 12 -1 12 -7 0
-10 4 -6 9 3 5 2 12 -2 14 -5 3 -18 -9 -30 -26z"
        />
        <path
          d="M4660 3760 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4435 3710 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4690 3710 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4595 3670 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4640 3660 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"
        />
        <path
          d="M4690 3556 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"
        />
        <path
          d="M4526 3541 c-14 -5 -16 -10 -8 -15 16 -9 54 3 47 15 -7 10 -13 10
-39 0z"
        />
        <path
          d="M4500 3500 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"
        />
        <path
          d="M4720 3491 c0 -6 -8 -11 -17 -12 -14 0 -15 -2 -3 -6 8 -4 21 -12 29
-20 17 -17 28 -8 13 10 -7 8 -9 16 -6 19 3 3 1 9 -5 13 -6 3 -11 1 -11 -4z"
        />
        <path
          d="M4825 3480 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4654 3471 c4 -5 0 -11 -6 -14 -7 -2 -10 -8 -6 -12 4 -4 11 -4 17 0
14 8 14 35 0 35 -5 0 -8 -4 -5 -9z"
        />
        <path
          d="M4697 3423 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
        />
        <path
          d="M4780 3410 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4755 3400 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4665 3380 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
        <path
          d="M4607 3365 c-10 -7 -14 -15 -10 -18 12 -8 36 9 32 21 -3 7 -10 6 -22
-3z"
        />
        <path
          d="M4690 3340 c-8 -5 -24 -7 -35 -3 -17 5 -18 4 -7 -9 10 -13 17 -14 37
-4 14 6 25 14 25 19 0 9 -2 9 -20 -3z"
        />
        <path
          d="M4775 3340 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4545 3300 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4665 3290 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4610 3274 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"
        />
        <path
          d="M4726 3275 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"
        />
        <path
          d="M4650 3250 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"
        />
        <path
          d="M4596 3231 c-9 -9 -16 -20 -16 -23 0 -12 45 -17 52 -5 4 6 15 12 24
12 10 0 19 6 21 13 3 7 -1 10 -10 6 -9 -3 -24 -1 -35 5 -15 8 -23 6 -36 -8z
m24 -11 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path
          d="M4825 3230 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4900 3220 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4775 3200 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4640 3195 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"
        />
        <path
          d="M4685 3170 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4730 3149 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4810 3139 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4780 3129 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3157 3506 c-10 -25 17 -38 31 -16 7 11 18 17 27 14 8 -4 15 -1 15 5
0 16 -26 14 -44 -4 -8 -8 -17 -15 -20 -15 -3 0 -3 7 0 15 4 8 4 15 1 15 -3 0
-7 -6 -10 -14z"
        />
        <path
          d="M3111 3499 c0 -9 6 -22 14 -29 10 -9 15 -9 15 -1 0 6 -4 11 -9 11 -5
0 -12 8 -14 18 -4 14 -5 14 -6 1z"
        />
        <path
          d="M3218 3482 c-10 -2 -18 -8 -18 -13 0 -6 5 -7 10 -4 6 3 10 -1 10 -9
0 -16 15 -22 23 -8 9 14 -8 38 -25 34z"
        />
        <path
          d="M3200 3439 c0 -5 -4 -8 -9 -5 -5 3 -14 -1 -20 -8 -10 -12 -8 -13 8
-9 15 4 23 -1 30 -18 7 -17 10 -19 10 -7 1 9 -3 19 -9 23 -5 3 -7 12 -3 20 3
8 2 15 -1 15 -3 0 -6 -5 -6 -11z"
        />
        <path
          d="M3120 3430 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3230 3421 c0 -5 9 -7 20 -4 11 3 20 7 20 9 0 2 -9 4 -20 4 -11 0
-20 -4 -20 -9z"
        />
        <path
          d="M3130 3387 c0 -28 27 -44 44 -26 8 8 6 10 -9 7 -14 -2 -19 2 -17 14
1 9 -2 19 -8 23 -6 4 -10 -4 -10 -18z"
        />
        <path
          d="M3283 3394 c0 -8 6 -14 14 -14 7 0 13 4 13 9 0 5 -6 11 -13 14 -8 3
-14 -1 -14 -9z"
        />
        <path
          d="M3263 3370 c7 -18 14 -25 18 -18 4 6 2 19 -4 30 -18 28 -27 21 -14
-12z"
        />
        <path d="M3325 3289 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
        <path
          d="M3276 3243 c7 -4 14 -19 17 -33 4 -22 5 -21 4 4 -1 17 -9 31 -17 33
-10 3 -11 2 -4 -4z"
        />
        <path
          d="M3316 3235 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"
        />
        <path
          d="M3360 3240 c0 -11 48 -25 57 -16 4 3 -4 6 -16 6 -12 0 -19 4 -16 10
3 6 -1 10 -9 10 -9 0 -16 -4 -16 -10z"
        />
        <path
          d="M3330 3186 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"
        />
        <path d="M3427 3153 c3 -7 9 -10 14 -7 14 8 11 21 -5 21 -8 0 -12 -6 -9 -14z" />
        <path
          d="M3460 3131 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15 -8
3 -15 1 -15 -3z"
        />
        <path d="M3366 3095 c4 -8 10 -12 15 -9 14 8 10 24 -6 24 -9 0 -12 -6 -9 -15z" />
        <path
          d="M3470 3069 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M2485 3400 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M2095 3300 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"
        />
        <path
          d="M2050 3280 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path d="M2089 3263 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
        <path
          d="M2132 3258 c2 -12 10 -23 18 -25 12 -4 13 -3 1 5 -8 6 -11 17 -8 26
4 9 2 16 -4 16 -6 0 -9 -10 -7 -22z"
        />
        <path
          d="M2414 3247 c3 -10 6 -21 6 -23 0 -2 12 -4 26 -4 15 0 23 4 19 11 -4
6 -13 8 -21 5 -7 -3 -18 3 -24 12 -11 16 -11 15 -6 -1z"
        />
        <path
          d="M2383 3224 c-17 -18 -16 -31 2 -38 8 -3 12 -12 9 -20 -3 -8 0 -17 7
-22 7 -4 10 -3 5 4 -3 6 -3 13 2 16 9 6 4 64 -7 70 -3 2 -12 -2 -18 -10z"
        />
        <path
          d="M2100 3220 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M2250 3210 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"
        />
        <path d="M2140 3206 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
        <path
          d="M2333 3173 c20 -5 23 -19 5 -26 -7 -2 -19 3 -26 12 -11 14 -12 13 -3
-5 6 -12 7 -24 4 -28 -3 -3 -1 -6 5 -6 5 0 13 6 15 13 4 9 8 8 16 -3 9 -12 11
-11 11 8 0 12 6 22 13 23 17 1 -25 18 -43 17 -10 0 -9 -2 3 -5z"
        />
        <path
          d="M2180 3160 c-11 -8 -7 -9 15 -4 37 8 45 14 19 14 -10 0 -26 -5 -34
-10z"
        />
        <path d="M2250 3156 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z" />
        <path
          d="M2145 3140 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"
        />
        <path d="M2381 3124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M2025 3120 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M2347 3104 c-12 -13 -7 -32 9 -38 14 -5 15 -4 4 9 -7 9 -10 19 -6 22
4 4 12 1 17 -7 9 -13 10 -13 7 -1 -5 17 -21 25 -31 15z"
        />
        <path
          d="M2340 3000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M5860 3250 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M5766 3194 c-9 -24 -2 -26 12 -4 7 11 8 20 3 20 -5 0 -12 -7 -15 -16z" />
        <path
          d="M5810 3169 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M5695 3140 c-3 -6 1 -10 9 -10 9 0 16 -4 16 -10 0 -5 5 -10 10 -10
16 0 11 14 -10 27 -12 8 -21 9 -25 3z"
        />
        <path
          d="M5870 3119 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M5785 3110 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M5645 3100 c-12 -19 6 -30 41 -27 35 4 58 16 33 18 -8 1 -20 1 -28 0
-7 -2 -10 2 -6 8 3 7 -2 11 -14 11 -11 0 -23 -4 -26 -10z m25 -10 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"
        />
        <path
          d="M5742 3088 c5 -15 28 -23 28 -10 0 5 -7 13 -16 16 -10 4 -14 1 -12
-6z"
        />
        <path
          d="M5637 3054 c16 -7 5 -34 -13 -34 -8 0 -14 -4 -14 -10 0 -5 10 -10 23
-10 12 0 32 -3 45 -6 14 -4 22 -2 22 6 0 7 -7 10 -15 6 -11 -4 -17 3 -22 24
-3 19 -11 30 -22 29 -9 0 -11 -3 -4 -5z"
        />
        <path
          d="M5715 3050 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M5790 3031 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z" />
        <path d="M5746 2987 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
        <path
          d="M5630 2950 c0 -34 7 -38 26 -13 9 13 9 22 2 30 -17 21 -28 15 -28
-17z"
        />
        <path
          d="M5880 2960 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M5680 2890 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path
          d="M5835 2890 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M5800 2880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M5672 2858 c2 -6 10 -14 16 -16 7 -2 10 2 6 12 -7 18 -28 22 -22 4z" />
        <path d="M5751 2844 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M5784 2792 c5 -20 2 -31 -14 -42 -21 -15 -27 -40 -10 -40 6 0 10 6
10 13 0 7 9 19 19 26 15 11 17 20 11 37 -5 12 -6 25 -3 28 4 3 1 6 -6 6 -9 0
-11 -9 -7 -28z"
        />
        <path
          d="M5728 2805 c-3 -6 0 -15 7 -22 10 -10 16 -9 27 4 11 14 11 16 -4 10
-9 -3 -18 -1 -21 6 -2 6 -6 7 -9 2z"
        />
        <path
          d="M5685 2790 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M5697 2756 c-19 -29 -14 -35 10 -11 8 8 13 20 11 26 -2 7 -12 0 -21
-15z"
        />
        <path
          d="M5660 2760 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M6015 2760 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M5722 2739 c-7 -12 -11 -24 -7 -27 7 -8 25 17 25 36 0 17 -3 15 -18
-9z"
        />
        <path
          d="M5780 2704 c0 -8 -4 -12 -10 -9 -5 3 -10 1 -10 -5 0 -7 7 -9 16 -6 9
3 18 6 20 6 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0 -10 -7 -10 -16z"
        />
        <path d="M5918 2683 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
        <path
          d="M5761 2638 c-1 -12 6 -18 19 -18 12 0 20 7 20 16 0 13 -3 14 -14 5
-11 -9 -15 -9 -19 1 -4 8 -6 7 -6 -4z"
        />
        <path
          d="M5950 2646 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"
        />
        <path
          d="M6020 2641 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15 -8
3 -15 1 -15 -3z"
        />
        <path
          d="M5914 2598 c-5 -8 -2 -9 9 -5 9 3 23 -1 32 -9 8 -9 15 -12 15 -7 0
26 -43 43 -56 21z"
        />
        <path
          d="M5750 2590 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M6057 2583 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
        />
        <path
          d="M6025 2570 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M5967 2542 c-19 -21 -22 -42 -7 -42 6 0 10 6 10 14 0 8 5 16 12 18 6
2 9 10 7 17 -3 9 -9 7 -22 -7z"
        />
        <path
          d="M6020 2541 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15 -8
3 -15 1 -15 -3z"
        />
        <path
          d="M5915 2490 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path
          d="M2557 3023 c-13 -12 -7 -25 8 -19 8 3 15 0 15 -6 0 -6 2 -9 5 -6 7 7
-5 38 -14 38 -4 0 -11 -3 -14 -7z"
        />
        <path d="M2518 2993 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M2526 2965 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"
        />
        <path
          d="M2556 2964 c-4 -9 -4 -19 -1 -22 3 -3 5 0 5 6 0 7 7 12 16 12 8 0 12
5 9 10 -9 15 -22 12 -29 -6z"
        />
        <path
          d="M2610 2959 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M2660 2951 c-16 -7 -25 -31 -11 -31 10 0 36 29 30 34 -2 2 -11 1 -19
-3z"
        />
        <path
          d="M2730 2920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M2703 2898 c4 -10 2 -18 -3 -18 -6 0 -9 -15 -9 -32 2 -28 2 -29 6 -8
3 14 10 31 16 38 7 9 6 16 -3 25 -10 10 -12 9 -7 -5z"
        />
        <path d="M2710 2831 c0 -25 6 -27 13 -6 4 8 2 17 -3 20 -6 4 -10 -3 -10 -14z" />
        <path
          d="M2845 2700 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M2820 2675 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"
        />
        <path
          d="M3700 2930 c0 -5 9 -12 20 -15 11 -4 18 -11 15 -16 -4 -5 -1 -9 4 -9
23 0 6 33 -21 43 -10 3 -18 2 -18 -3z"
        />
        <path
          d="M4060 2930 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4137 2913 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
        />
        <path
          d="M3676 2874 c-5 -12 -2 -15 10 -10 8 3 20 6 25 6 6 0 7 5 4 10 -10 15
-32 12 -39 -6z"
        />
        <path
          d="M3695 2850 c-3 -5 1 -10 10 -10 9 0 23 -7 32 -15 8 -8 20 -12 26 -8
6 4 2 9 -11 14 -12 5 -22 13 -22 19 0 13 -27 13 -35 0z"
        />
        <path
          d="M4110 2840 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4186 2835 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"
        />
        <path
          d="M3635 2810 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
        <path
          d="M3865 2810 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3685 2790 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3875 2700 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3795 2640 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M4520 1988 c0 -11 22 -10 39 1 10 7 7 9 -12 8 -15 -1 -27 -5 -27 -9z" />
        <path
          d="M4617 1980 c-3 -11 -1 -20 4 -20 5 0 9 2 9 4 0 2 3 11 6 20 3 9 2 16
-4 16 -5 0 -12 -9 -15 -20z"
        />
        <path
          d="M4445 1980 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4576 1972 c-4 -10 -3 -28 1 -38 6 -15 8 -14 15 11 8 33 -6 58 -16
27z"
        />
        <path d="M4485 1937 c-10 -8 -14 -17 -8 -20 5 -3 17 3 26 14 19 22 9 25 -18 6z" />
        <path d="M4517 1900 c3 -11 9 -17 14 -15 10 7 1 35 -11 35 -5 0 -6 -9 -3 -20z" />
        <path
          d="M4575 1873 c-3 -15 -12 -31 -18 -35 -16 -10 -3 -20 16 -13 18 8 32
54 19 67 -6 6 -12 -1 -17 -19z"
        />
        <path
          d="M3750 1839 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4497 1829 c-3 -13 -1 -19 6 -16 15 5 22 37 9 37 -5 0 -12 -9 -15
-21z"
        />
        <path
          d="M4610 1841 c0 -14 29 -41 45 -41 12 0 25 21 25 41 0 14 -27 11 -34
-3 -5 -10 -7 -10 -12 0 -7 14 -24 16 -24 3z"
        />
        <path
          d="M4576 1805 c-10 -8 -24 -16 -30 -18 -6 -2 -7 -8 -3 -13 4 -5 12 -3
16 4 8 13 49 4 50 -10 1 -5 3 -8 6 -8 3 0 10 0 15 0 6 0 10 5 10 11 0 5 -5 7
-10 4 -6 -3 -14 5 -20 19 -11 30 -9 30 -34 11z"
        />
        <path
          d="M3710 1789 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4440 1780 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M4495 1780 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4330 1770 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4462 1738 c-14 -14 -16 -28 -3 -28 9 0 24 30 18 36 -2 2 -9 -2 -15
-8z"
        />
        <path
          d="M4490 1740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M4560 1744 c0 -17 17 -36 24 -26 3 5 -1 15 -9 22 -8 7 -15 9 -15 4z" />
        <path
          d="M4410 1729 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4600 1730 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4520 1713 c0 -7 -5 -13 -11 -13 -5 0 -7 -5 -4 -10 4 -6 16 -3 30 7
30 22 31 32 5 30 -11 -1 -20 -7 -20 -14z"
        />
        <path
          d="M4630 1710 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4410 1699 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3715 1690 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3815 1690 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4443 1666 c0 -24 24 -43 36 -30 3 4 0 9 -6 11 -7 3 -13 13 -13 24 0
10 -4 19 -9 19 -5 0 -8 -11 -8 -24z"
        />
        <path
          d="M4410 1669 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4330 1660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3730 1642 c0 -4 9 -13 20 -20 22 -13 27 -3 8 16 -14 14 -28 16 -28
4z"
        />
        <path
          d="M4377 1620 c-3 -11 -1 -20 4 -20 5 0 9 2 9 4 0 2 3 11 6 20 3 9 2 16
-4 16 -5 0 -12 -9 -15 -20z"
        />
        <path
          d="M4498 1618 c18 -22 52 -30 52 -12 0 8 -6 14 -14 14 -8 0 -23 3 -33 7
-15 6 -16 4 -5 -9z"
        />
        <path
          d="M3795 1610 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4450 1610 c-8 -5 -12 -11 -9 -14 2 -3 11 -1 19 4 8 5 12 11 9 14 -2
3 -11 1 -19 -4z"
        />
        <path
          d="M4400 1569 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4440 1569 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4360 1559 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4470 1550 c-10 -6 -11 -10 -2 -10 6 0 12 -7 12 -15 0 -20 16 -19 24
1 11 29 -7 42 -34 24z"
        />
        <path
          d="M3747 1530 c-3 -11 -1 -20 4 -20 5 0 9 2 9 4 0 2 3 11 6 20 3 9 2 16
-4 16 -5 0 -12 -9 -15 -20z"
        />
        <path
          d="M4541 1534 c11 -13 10 -17 -4 -26 -10 -5 -15 -14 -11 -19 3 -6 14 -1
25 12 16 18 16 23 4 35 -19 19 -30 18 -14 -2z"
        />
        <path
          d="M4407 1516 c-6 -16 2 -28 14 -20 12 7 11 34 0 34 -5 0 -11 -6 -14
-14z"
        />
        <path
          d="M3710 1509 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3840 1510 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4335 1510 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3815 1500 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4592 1493 c2 -12 -4 -19 -19 -21 -27 -4 -28 -8 -7 -29 13 -13 13
-17 2 -24 -10 -7 -10 -9 0 -9 7 0 15 7 18 15 4 8 12 15 20 15 17 0 18 17 2 23
-10 4 -10 8 1 15 7 5 10 15 7 21 -11 17 -27 13 -24 -6z"
        />
        <path
          d="M3676 1483 c-4 -9 -2 -21 4 -27 7 -7 10 -7 10 1 0 6 3 18 6 27 3 9 2
16 -4 16 -5 0 -13 -8 -16 -17z"
        />
        <path
          d="M4463 1493 c-7 -2 -13 -9 -13 -15 0 -6 6 -8 14 -5 7 3 19 0 25 -6 12
-12 14 -7 5 17 -6 17 -11 18 -31 9z"
        />
        <path
          d="M3635 1480 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"
        />
        <path
          d="M3740 1480 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M3775 1480 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4335 1480 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3710 1470 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4450 1450 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M4515 1440 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
        <path
          d="M3650 1423 c0 -10 6 -12 20 -8 12 4 20 2 20 -6 0 -9 3 -9 14 0 11 9
15 9 19 -1 2 -7 8 -9 13 -5 12 11 -24 31 -58 33 -18 1 -28 -4 -28 -13z"
        />
        <path
          d="M3751 1430 c0 -8 4 -24 8 -34 6 -17 8 -17 14 -4 3 9 -1 24 -8 35 -11
14 -14 15 -14 3z"
        />
        <path
          d="M3875 1430 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M4235 1430 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path d="M3605 1420 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
        <path
          d="M4400 1420 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4537 1423 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
        />
        <path
          d="M4615 1400 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M3880 1380 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M4500 1382 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z" />
        <path d="M4560 1381 c0 -17 18 -21 24 -6 3 9 0 15 -9 15 -8 0 -15 -4 -15 -9z" />
        <path
          d="M4424 1363 c10 -11 36 -12 36 -2 0 5 -10 9 -22 9 -12 0 -18 -3 -14
-7z"
        />
        <path
          d="M3580 1350 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M3745 1349 c-4 -5 5 -9 19 -9 14 0 26 4 26 8 0 11 -39 12 -45 1z" />
        <path d="M4544 1349 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16 11z" />
        <path
          d="M4600 1325 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3710 1310 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M3890 1309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path d="M3669 1310 c-8 -21 -3 -60 8 -60 7 0 13 6 12 13 -1 15 -17 54 -20 47z" />
        <path
          d="M3741 1279 c-11 -19 -11 -21 4 -15 9 3 19 6 21 6 2 0 4 7 4 15 0 21
-13 19 -29 -6z"
        />
        <path
          d="M4560 1290 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4610 1269 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4570 1260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3610 1251 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"
        />
        <path d="M4667 1253 c-7 -11 10 -40 18 -31 4 4 3 14 -3 24 -5 9 -12 12 -15 7z" />
        <path
          d="M3756 1243 c-11 -11 -6 -21 14 -26 11 -3 20 -3 20 0 0 10 -28 32 -34
26z"
        />
        <path
          d="M3685 1230 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"
        />
        <path d="M3823 1223 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
        <path
          d="M3635 1200 c-17 -19 -16 -19 14 -17 32 2 42 21 17 30 -8 3 -22 -3
-31 -13z"
        />
        <path
          d="M4620 1200 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M3580 1180 c19 -13 30 -13 30 0 0 6 -10 10 -22 10 -19 0 -20 -2 -8
-10z"
        />
        <path
          d="M3690 1168 c0 -18 -2 -20 -9 -9 -8 12 -12 12 -26 -2 -19 -19 -19 -19
15 -22 14 -1 28 2 32 9 11 16 10 46 -2 46 -5 0 -10 -10 -10 -22z"
        />
        <path
          d="M3870 1180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3810 1160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3740 1140 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M4660 1140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4600 1130 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4530 1110 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M3875 1070 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3840 1054 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"
        />
        <path
          d="M3675 1010 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path d="M3725 1002 c-25 -5 -45 -22 -24 -21 7 1 25 4 39 8 28 8 15 20 -15 13z" />
        <path
          d="M3812 1002 c-13 -9 -6 -42 9 -42 4 0 6 7 3 15 -4 8 -1 15 5 15 6 0
11 5 11 10 0 12 -10 13 -28 2z"
        />
        <path
          d="M3730 970 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3780 969 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3880 952 c0 -4 9 -8 20 -9 11 -1 20 2 20 8 0 5 -9 9 -20 9 -11 0
-20 -4 -20 -8z"
        />
        <path
          d="M3780 930 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4380 2890 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4930 2889 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4950 2870 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4985 2850 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M2865 2790 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3004 2715 c-10 -26 4 -134 26 -201 27 -80 95 -181 148 -218 63 -45
62 -24 -2 35 -91 83 -167 267 -149 360 6 30 -14 49 -23 24z"
        />
        <path
          d="M2950 2770 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4920 2700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4965 2680 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4946 2531 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z"
        />
        <path
          d="M1280 1750 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M1326 1705 c-3 -8 -12 -15 -21 -15 -8 0 -15 -4 -15 -9 0 -14 22 -27
70 -40 83 -24 220 -89 301 -142 71 -47 102 -58 85 -31 -13 22 -198 125 -296
165 -89 37 -105 47 -104 65 1 25 -11 29 -20 7z"
        />
        <path d="M1380 1711 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
        <path
          d="M1230 1700 c0 -7 -8 -9 -20 -5 -24 8 -28 -13 -4 -22 12 -5 13 -7 2
-14 -10 -7 -10 -11 0 -21 10 -10 14 -10 21 1 5 7 14 10 20 7 6 -4 11 -3 11 3
0 5 -5 13 -11 16 -6 4 -8 13 -5 21 3 7 1 16 -4 19 -6 4 -10 1 -10 -5z"
        />
        <path
          d="M1260 1679 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M1310 1609 c0 -11 4 -18 9 -14 5 3 17 -2 26 -11 12 -13 19 -14 28 -5
8 8 7 11 -6 11 -9 0 -21 9 -27 20 -14 26 -30 26 -30 -1z"
        />
        <path
          d="M1334 1485 c3 -9 0 -15 -10 -15 -14 0 -14 -2 0 -15 19 -20 23 -19 29
5 3 11 13 20 22 20 9 0 13 5 10 10 -3 6 -18 10 -32 10 -18 0 -24 -4 -19 -15z"
        />
        <path d="M1468 1483 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M1367 1415 c-4 -8 -2 -17 3 -20 6 -4 10 -4 10 -1 0 2 3 11 6 20 3 9
2 16 -4 16 -5 0 -12 -7 -15 -15z"
        />
        <path
          d="M1435 1410 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path
          d="M1350 1375 c0 -18 23 -32 28 -17 7 22 38 12 55 -18 10 -16 23 -30 28
-30 6 0 8 4 4 9 -3 6 3 15 12 21 15 9 14 10 -9 5 -20 -4 -28 -1 -28 8 0 8 -10
18 -22 24 -33 15 -68 14 -68 -2z"
        />
        <path d="M1501 1331 c-9 -6 -7 -10 8 -14 12 -3 21 -1 21 3 0 14 -15 19 -29 11z" />
        <path d="M1751 1324 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M1374 1305 c-9 -14 -14 -29 -10 -33 4 -3 11 0 15 7 5 7 14 10 20 7 6
-4 11 -2 11 5 0 10 3 10 12 1 14 -14 -8 -46 -25 -35 -7 4 -8 0 -4 -11 4 -10 7
-20 7 -22 0 -2 7 -4 15 -4 8 0 15 9 15 20 0 11 5 20 10 20 6 0 10 6 10 14 0 8
6 17 13 20 8 4 7 5 -3 4 -22 -2 -51 13 -44 23 13 22 -27 6 -42 -16z"
        />
        <path
          d="M1570 1305 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"
        />
        <path d="M1638 1313 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
        <path
          d="M1750 1290 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"
        />
        <path d="M1461 1264 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1530 1264 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
        <path
          d="M1585 1270 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"
        />
        <path
          d="M1680 1270 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M1760 1255 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1630 1220 c0 -5 7 -10 15 -10 8 0 15 -9 15 -20 0 -11 6 -20 14 -20
21 0 31 21 15 31 -12 8 -12 11 1 19 11 7 6 10 -22 10 -21 0 -38 -4 -38 -10z"
        />
        <path
          d="M10040 8631 c-22 -6 -25 -12 -24 -54 1 -95 209 -463 375 -662 42 -49
149 -160 240 -245 430 -405 565 -548 676 -715 92 -138 373 -624 373 -645 0 -4
7 -14 16 -21 15 -12 16 -11 9 11 -31 105 -163 394 -245 539 -74 129 -155 230
-390 481 -379 405 -600 687 -861 1100 -145 228 -138 219 -169 211z"
        />
        <path
          d="M11575 6098 c-284 -557 -938 -1756 -1344 -2463 -28 -49 -51 -95 -51
-102 0 -22 40 -37 65 -23 49 26 187 230 363 537 231 401 641 1197 771 1493 92
211 304 740 299 746 -3 2 -49 -83 -103 -188z"
        />
        <path
          d="M10055 3453 c-11 -3 -62 -18 -113 -35 -78 -24 -95 -34 -103 -55 -15
-39 1 -49 53 -34 54 16 163 73 203 106 l30 24 -25 -1 c-14 0 -34 -3 -45 -5z"
        />
      </g>
    </svg>
  );
}
