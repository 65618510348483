import { Container } from './Container';

export function Footer() {
  return (
    <footer className="flex items-right py-16">
      <Container className="md:flex-row">
        <p className="mt-6 text-base text-slate-500 md:mt-0">
          Copyright &copy; {new Date().getFullYear()} All rights reserved.
        </p>
      </Container>
    </footer>
  );
}
